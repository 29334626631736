import { LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import { type Activity as AType } from '~/graphql/types'

export default function Activity({ data }: { data: AType }) {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="bg-white lg:rounded-[10px] rounded-[5px] shadow-eh-primary h-full flex flex-col">
      <div className="relative aspect-video bg-gradient-to-b lg:rounded-[10px] rounded-[5px] to-[#EFF2FB] from-white min-h-[269px]">
        <LossePlaatjie
          maxwidth={808}
          className="lg:rounded-[10px] rounded-[5px] w-full h-full object-cover"
          src={data.recap?.image}
          alt="activity-image"
        />
      </div>
      <div
        className={clsx(
          data.recap?.persons || data.recap?.price || data.recap?.price ? `gap-4 lg:gap-6` : `gap-2`,
          'p-4 lg:p-8 lg:pb-6 pt-4 flex flex-col flex-grow'
        )}
      >
        <h2 className="text-xl font-bold">{data.title}</h2>
        {data.recap?.description && (
          <Content className="content children-p:line-clamp-5 children-p:mb-3">{data.recap?.description}</Content>
        )}
        {(data.recap?.persons || data.recap?.price || data.recap?.price) && (
          <div className="mt-auto">
            <div className="flex flex-col gap-2">
              {data.recap?.persons && (
                <div className="bg-[#EFF2FB] font-bold flex items-center gap-3 rounded-full px-6 py-[6px]">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.5 7.5C9.57107 7.5 11.25 5.82107 11.25 3.75C11.25 1.67893 9.57107 0 7.5 0C5.42893 0 3.75 1.67893 3.75 3.75C3.75 5.82107 5.42893 7.5 7.5 7.5Z"
                      fill="black"
                      fillOpacity="0.8"
                    />
                    <path
                      d="M7.5 8.74939C4.39482 8.75285 1.87846 11.2692 1.875 14.3744C1.875 14.7196 2.15481 14.9994 2.49999 14.9994H12.5C12.8452 14.9994 13.125 14.7196 13.125 14.3744C13.1215 11.2692 10.6052 8.75282 7.5 8.74939Z"
                      fill="black"
                      fillOpacity="0.8"
                    />
                  </svg>
                  {data.recap.persons}
                </div>
              )}
              {data.recap?.price && (
                <div className="bg-[#EFF2FB] font-bold flex items-center gap-3 rounded-full px-6 py-[6px]">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_221_1972)">
                      <path
                        d="M4.375 2.5C6.79125 2.5 8.75 1.94036 8.75 1.25C8.75 0.559644 6.79125 0 4.375 0C1.95875 0 0 0.559644 0 1.25C0 1.94036 1.95875 2.5 4.375 2.5Z"
                        fill="black"
                      />
                      <path
                        d="M4.375 5.31251C6.79125 5.31251 8.75 4.75313 8.75 4.06251V2.64563C7.3725 3.38375 5.0025 3.43751 4.375 3.43751C3.7475 3.43751 1.3775 3.38375 0 2.64563V4.06251C0 4.75313 1.95875 5.31251 4.375 5.31251Z"
                        fill="black"
                      />
                      <path
                        d="M5.9375 11.7938V13.6625C5.45625 13.7188 4.925 13.75 4.375 13.75C1.95625 13.75 0 13.1875 0 12.5V11.0813C1.375 11.8188 3.75 11.875 4.375 11.875C4.64375 11.875 5.2375 11.8625 5.9375 11.7938Z"
                        fill="black"
                      />
                      <path
                        d="M6.00625 8.97505C5.9875 9.0313 5.975 9.10005 5.9625 9.16255L5.9375 9.1438V10.85C5.45625 10.9063 4.925 10.9375 4.375 10.9375C1.95625 10.9375 0 10.375 0 9.68755V8.2688C1.375 9.0063 3.75 9.06255 4.375 9.06255C4.65 9.06255 5.275 9.05005 6.00625 8.97505Z"
                        fill="black"
                      />
                      <path
                        d="M4.375 6.25001C3.7475 6.25001 1.3775 6.19625 0 5.45813V6.87501C0 7.56563 1.95875 8.12501 4.375 8.12501C6.79125 8.12501 8.75 7.56563 8.75 6.87501V5.45813C7.3725 6.19625 5.0025 6.25001 4.375 6.25001Z"
                        fill="black"
                      />
                      <path
                        d="M10.9375 10.625C13.1812 10.625 15 10.0654 15 9.375C15 8.68464 13.1812 8.125 10.9375 8.125C8.69384 8.125 6.875 8.68464 6.875 9.375C6.875 10.0654 8.69384 10.625 10.9375 10.625Z"
                        fill="black"
                      />
                      <path
                        d="M10.9375 12.8125C13.1813 12.8125 15 12.2531 15 11.5625V10.7706C13.7213 11.5094 11.52 11.5625 10.9375 11.5625C10.355 11.5625 8.15375 11.5088 6.875 10.7706V11.5625C6.875 12.2531 8.69375 12.8125 10.9375 12.8125Z"
                        fill="black"
                      />
                      <path
                        d="M10.9375 13.75C10.355 13.75 8.15375 13.6963 6.875 12.9581V13.75C6.875 14.4406 8.69375 15 10.9375 15C13.1813 15 15 14.4406 15 13.75V12.9581C13.7213 13.6963 11.52 13.75 10.9375 13.75Z"
                        fill="black"
                      />
                    </g>
                  </svg>

                  {data.recap.price}
                </div>
              )}
              {data.recap?.time && (
                <div className="bg-[#EFF2FB] font-bold flex items-center gap-3 rounded-full px-6 py-[6px]">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.5 0C6.01664 0 4.5666 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324965 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9979 5.51154 14.207 3.60513 12.8009 2.19907C11.3949 0.793018 9.48847 0.00215068 7.5 0V0ZM8.125 7.42438C8.12507 7.53032 8.0982 7.63454 8.04693 7.72725C7.99565 7.81996 7.92165 7.89812 7.83188 7.95438L5.43188 9.45438C5.36211 9.49796 5.28445 9.52737 5.20332 9.54094C5.12218 9.55451 5.03917 9.55196 4.95902 9.53345C4.87888 9.51493 4.80316 9.48081 4.7362 9.43304C4.66923 9.38526 4.61234 9.32477 4.56875 9.255C4.52517 9.18524 4.49576 9.10757 4.48219 9.02644C4.46862 8.9453 4.47117 8.8623 4.48968 8.78215C4.5082 8.702 4.54231 8.62628 4.59009 8.55932C4.63787 8.49236 4.69836 8.43546 4.76813 8.39188L6.875 7.07813V4.375C6.875 4.20924 6.94085 4.05027 7.05806 3.93306C7.17527 3.81585 7.33424 3.75 7.5 3.75C7.66576 3.75 7.82474 3.81585 7.94195 3.93306C8.05916 4.05027 8.125 4.20924 8.125 4.375V7.42438Z"
                      fill="black"
                      fillOpacity="0.8"
                    />
                  </svg>
                  {data.recap.time}
                </div>
              )}
            </div>
            <div className="flex justify-start mt-4">
              <Button
                as="button"
                arrow
                className="btn primary-link"
                onClick={() => {
                  setSearchParams(
                    (params) => {
                      params.set('_request', data.title || '')
                      return params
                    },
                    {
                      state: {
                        scroll: false
                      }
                    }
                  )
                }}
              >
                {data.recap?.request?.title || 'Doe een aanvraag'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
