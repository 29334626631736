import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import Form from '~/components/elements/Form'
import Content from '~/components/elements/Content'
import EmployeeCTA from '~/components/elements/EmployeeCTA'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form, Vacancy } from '~/graphql/types'
import { useLoaderData } from '@ubo/losse-sjedel'

export default function FormApply({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const { page } = useLoaderData<{ page: Vacancy }>()

  return (
    <section id="aanvraag" data-component="FormApply" className="section">
      <div className="container">
        <div className="max-w-screen-xl flex flex-col gap-8 lg:gap-16 mx-auto p-5 md:p-12 lg:p-20 bg-white shadow-eh-primary lg:rounded-[10px] rounded-[5px]">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-20">
            <Content className="content">{fields.description}</Content>
            {fields.employee && (
              <div className="flex lg:justify-start justify-center">
                <EmployeeCTA noBox fields={fields.employee} />
              </div>
            )}
          </div>
          <div className="relative">
            <Form
              defaultFields={{
                input_7: page.title || ''
              }}
              generate={false}
              data={fields.form}
              privacyUrl="/privacyverklaring/"
              privacyUrlPlacement="Privacy"
              renderLoading={() => (
                <div className="absolute left-0 top-0 w-full h-full bg-white/75 z-10 flex-center">
                  <Loading />
                </div>
              )}
              renderStatus={({ content }) => <Content className="content">{content}</Content>}
            >
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-5">
                <div className="col-span-1 lg:col-span-2">
                  <FormDuufGroup showIds={[1]} />
                </div>
                <FormDuufGroup showIds={[3, 4]} />
                <div className="col-span-1 lg:col-span-2">
                  <FormDuufGroup showIds={[8]} />
                </div>

                <div className="col-span-1 lg:col-span-2 grid gap-3 lg:gap-5 grid-cols-3">
                  <div className="col-span-2">
                    <FormDuufGroup showIds={[9]} />
                  </div>
                  <div className="flex justify-end items-center">
                    <FormDuufSubmit />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}
