import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import ContentWave from '~/components/elements/ContentWave'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithGallery({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section className={clsx(fields.haswave && 'lg:mb-7', 'section relative max-w-[2000px] mx-auto')} data-component="ContentWithGallery">
      {fields.haswave && <ContentWave />}
      <div className="container">
        <div className="max-w-[1330px] mx-auto grid gap-8 lg:gap-24 col-span-4 lg:grid-cols-7">
          <div className="col-span-4 flex gap-10 flex-col justify-center">
            <Content className="content">{fields.description}</Content>
            {fields.links && (
              <div className="flex items-center gap-2">
                {fields.links.map((link, index) => (
                  <Button arrow className={clsx(index >= 1 && 'secondary', 'btn')} key={index} to={link?.link?.url || '/'}>
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
          <div className={clsx(fields.flipColumns && 'order-first', 'col-span-4 lg:col-span-3')}>
            <div className="grid gap-3 lg:gap-5 grid-cols-2">
              {fields.gallery![0] && (
                <div className="col-span-2 pl-10 md:pl-20">
                  <LossePlaatjie
                    maxwidth={824}
                    className="rounded-[5px] lg:rounded-[10px]"
                    src={fields.gallery![0]}
                    alt="gallery-image-1"
                  />
                </div>
              )}
              {fields.gallery![1] && (
                <div className="col-span-1">
                  <LossePlaatjie
                    maxwidth={472}
                    className="rounded-[5px] lg:rounded-[10px]"
                    src={fields.gallery![1]}
                    alt="gallery-image-1"
                  />
                </div>
              )}
              {fields.gallery![2] && (
                <div className="col-span-1 pr-8 lg:pr-14">
                  <LossePlaatjie
                    maxwidth={360}
                    className="rounded-[5px] lg:rounded-[10px]"
                    src={fields.gallery![2]}
                    alt="gallery-image-1"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
