import { useEffect, useState } from 'react'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import Modal from '~/components/elements/Modal'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function Popup({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const [formModalOpen, setFormModalOpen] = useState(false)

  // set formModalOpen to true after 2 seconds
  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem('popup')) return
      setFormModalOpen(true)
      sessionStorage.setItem('popup', 'true')
    }, 2000)
  }, [])

  return (
    <div>
      <Modal custom isOpen={formModalOpen} requestClose={() => setFormModalOpen(false)} className="!p-0">
        <a href={fields?.link?.url} rel="noreferrer" target="_blank">
          <LossePlaatjie src={fields?.image} className="w-[650px] h-auto" />
        </a>
      </Modal>
    </div>
  )
}
