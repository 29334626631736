import clsx from 'clsx'
import Content from '~/components/elements/Content'
import ContentWave from '~/components/elements/ContentWave'
import EmployeeCTA from '~/components/elements/EmployeeCTA'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithCta({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentWithCta" className={clsx(fields.haswave && 'lg:mb-40', 'section relative ')}>
      {fields.haswave && <ContentWave />}
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
          {fields.description && (
            <div className="col-span-1">
              <Content className="content">{fields?.description}</Content>
            </div>
          )}
          {fields.employee && (
            <div className="col-span-1 relative z-30 flex justify-center lg:justify-end mt-12 md:mt-14 lg:mt-0 lg:ml-32">
              <EmployeeCTA fields={fields.employee} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
