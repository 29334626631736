import { LossePlaatjie } from '@ubo/losse-sjedel'
import { type Page } from '~/graphql/types'
import Button from './Button'
import CustomLosseLink from './CustomLosseLink'

export default function ChildLink({ data, readmore = 'Lees verder' }: { data: Page; readmore?: string }) {
  return (
    <CustomLosseLink to={data.uri || '/'}>
      <div className="bg-white relative group h-full lg:rounded-[10px] rounded-[5px] shadow-eh-primary">
        <div className="aspect-video w-full lg:rounded-[10px] rounded-[5px] overflow-hidden relative">
          <LossePlaatjie
            src={data.featuredImage?.node}
            className="w-full h-full group-hover:scale-105 smooth object-cover"
            placeholder="blur"
            maxwidth={1000}
            title={data.title || ''}
          />
        </div>
        <div className="flex flex-col justify-between p-5 sm:p-6 lg:px-12 lg:py-7">
          <div>
            <h2 className="swiper-no-swiping text-black/80 font-bold text-lg lg:text-2xl">{data.title}</h2>
          </div>

          <div className="flex justify-start">
            <Button arrow className="btn primary-link" to={data.uri}>
              {readmore}
            </Button>
          </div>
        </div>
      </div>
    </CustomLosseLink>
  )
}
