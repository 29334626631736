import type { Experience, Page_Flexcontent_Flex_Experiences } from '~/graphql/types'
import Slider, { Slide } from '~/components/elements/Slider'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Button from '~/components/elements/Button'
import { Navigation } from 'swiper'
import ContentWave from '~/components/elements/ContentWave'
import clsx from 'clsx'

export default function ExperiencesHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Experiences }) {
  return (
    <section data-component="ExperiencesHighlighted" className={clsx(fields.haswave && 'lg:mb-20', 'section relative')}>
      {fields.haswave && <ContentWave />}
      <div className="container lg:px-16">
        <div className="flex flex-wrap text-black/80 items-end mb-4 lg:mb-11 lg:ml-6">
          {fields.description && <Content className="content mr-7">{fields.description}</Content>}
          {fields.link && (
            <Button arrow to={fields.link?.url} className="transparent !p-0 !font-light [&_svg>path]:!fill-black">
              {fields.link?.title}
            </Button>
          )}
        </div>
        <div className="relative">
          <div className="flex justify-end mb-4 lg:mb-0">
            <button
              type="button"
              className="custom-experienceshighlighted-button-prev rotate-180 text-[0] lg:absolute lg:top-44 lg:-left-14 2xl:-left-20 mr-4 lg:mr-0"
            >
              <Arrow />
              Vorige
            </button>
            <button
              type="button"
              className="custom-experienceshighlighted-button-next text-[0] lg:absolute lg:top-44 lg:-right-14 2xl:-right-20"
            >
              <Arrow />
              Volgende
            </button>
          </div>

          <Slider
            modules={[Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 1,
                autoHeight: true
              },
              991: {
                slidesPerView: 1,
                autoHeight: false
              }
            }}
            navigation={{
              prevEl: '.custom-experienceshighlighted-button-prev',
              nextEl: '.custom-experienceshighlighted-button-next'
            }}
            className="rounded-xl overflow-hidden"
          >
            {fields?.posts?.edges?.map((item) => {
              const experience = item.node as Experience

              return (
                <Slide key={item.node.databaseId} className="max-w-full">
                  <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
                    <div className="col-span-1 lg:mr-8 2xl:mr-20 mb-4 lg:mb-0">
                      <LossePlaatjie
                        maxwidth={1052}
                        src={experience.recap?.image}
                        className="rounded-lg object-cover h-[250px] xs:h-[275px] md:h-[325px] xl:h-[375px]"
                      />
                    </div>
                    <div className="col-span-1">
                      <h3 className="text-2xl font-bold mb-4 lg:mb-7">{experience.title}</h3>
                      <Content className="content">{experience.recap?.excerpt}</Content>
                      <Button to={experience.uri} arrow className="mt-7">
                        Lees verder
                      </Button>
                    </div>
                  </div>
                </Slide>
              )
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}

const Arrow = ({ className }: { className?: string }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? undefined}>
    <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 40 0)" fill="#E9A871" />
    <path
      d="M18.2006 26.7381C18.1067 26.6453 18.0321 26.5348 17.9811 26.413C17.9302 26.2913 17.9038 26.1606 17.9035 26.0286C17.9033 25.8966 17.9292 25.7658 17.9798 25.6439C18.0303 25.5219 18.1045 25.4112 18.1981 25.3181L22.7699 20.7299C22.8635 20.6368 22.9377 20.5261 22.9882 20.4041C23.0388 20.2822 23.0647 20.1514 23.0644 20.0194C23.0642 19.8874 23.0378 19.7567 22.9868 19.635C22.9359 19.5132 22.8613 19.4027 22.7674 19.3099L18.1792 14.7381C18.0853 14.6453 18.0107 14.5348 17.9597 14.4131C17.9088 14.2913 17.8824 14.1606 17.8822 14.0286C17.8819 13.8966 17.9078 13.7659 17.9584 13.6439C18.0089 13.522 18.0831 13.4112 18.1767 13.3181C18.3637 13.1315 18.617 13.0265 18.8812 13.026C19.1454 13.0256 19.399 13.1297 19.5867 13.3156L24.1848 17.8974C24.7476 18.4589 25.0646 19.2208 25.066 20.0158C25.0674 20.8108 24.7532 21.5739 24.1924 22.1374L19.6106 26.7356C19.4236 26.9221 19.1703 27.0271 18.9061 27.0276C18.6419 27.0281 18.3883 26.924 18.2006 26.7381Z"
      fill="white"
    />
  </svg>
)
