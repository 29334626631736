import { losseLoader, LossePlaatjie, useFetcher } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect } from 'react'
import Content from '~/components/elements/Content'
import CustomLosseLink from '~/components/elements/CustomLosseLink'
import type { InstagramFeedItem, Maybe, MediaItem, Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function InstagramFeed({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const fetcher = useFetcher<{ instagramFeed: InstagramFeedItem[] }>()

  useEffect(() => {
    if (fetcher.type === 'init') {
      fetcher.load(
        losseLoader({
          name: 'InstagramFeed'
        })
      )
    }
  }, [fetcher])

  return (
    <section data-component="InstagramFeed" className="section">
      <div className="container lg:py-20 flex flex-wrap items-center">
        <div className="flex flex-col sm:flex-row justify-between items-end w-full mb-14">
          <Content className="content mr-7 text-black/80 mb-4 sm:mb-0">{fields.description}</Content>
          <CustomLosseLink to={fields.link?.url || '/'} className="group flex flex-wrap items-center text-sm sm:text-base">
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-4 group-hover:animate-pulse"
            >
              <g clipPath="url(#clip0_86_1261)">
                <path
                  d="M13.5 16.2686C15.029 16.2686 16.2686 15.029 16.2686 13.5C16.2686 11.971 15.029 10.7314 13.5 10.7314C11.971 10.7314 10.7314 11.971 10.7314 13.5C10.7314 15.029 11.971 16.2686 13.5 16.2686Z"
                  fill="#E9A871"
                />
                <path
                  d="M13.5 0.316406C6.21891 0.316406 0.316406 6.21891 0.316406 13.5C0.316406 20.7811 6.21891 26.6836 13.5 26.6836C20.7811 26.6836 26.6836 20.7811 26.6836 13.5C26.6836 6.21891 20.7811 0.316406 13.5 0.316406ZM21.6452 16.8507C21.582 18.1041 21.2298 19.3401 20.3163 20.2438C19.3941 21.1561 18.1522 21.4961 16.8868 21.5587H10.1133C8.84767 21.4961 7.60598 21.1563 6.68371 20.2438C5.77025 19.3401 5.41809 18.1041 5.35481 16.8507V10.1493C5.41809 8.89587 5.7703 7.65988 6.68371 6.75612C7.60598 5.84381 8.84783 5.50389 10.1133 5.44129H16.8867C18.1523 5.50389 19.394 5.84365 20.3163 6.75612C21.2298 7.65988 21.5819 8.89587 21.6452 10.1493L21.6452 16.8507Z"
                  fill="#E9A871"
                />
                <path
                  d="M16.8012 6.96802C15.1513 6.92277 11.8491 6.92277 10.1992 6.96802C9.34068 6.99159 8.36736 7.20532 7.75564 7.8587C7.11998 8.53787 6.87977 9.35841 6.85541 10.2764C6.81259 11.8875 6.85541 16.7232 6.85541 16.7232C6.88331 17.6411 7.11998 18.4617 7.75564 19.1409C8.36736 19.7945 9.34068 20.008 10.1992 20.0316C11.8491 20.0768 15.1513 20.0768 16.8012 20.0316C17.6597 20.008 18.6331 19.7943 19.2448 19.1409C19.8804 18.4617 20.1206 17.6412 20.145 16.7232V10.2764C20.1206 9.35841 19.8804 8.53787 19.2448 7.8587C18.6328 7.20511 17.6595 6.99159 16.8012 6.96802ZM13.5 17.7911C12.6513 17.7911 11.8216 17.5394 11.1159 17.0679C10.4102 16.5963 9.86018 15.9261 9.53539 15.142C9.21059 14.3579 9.12561 13.495 9.29119 12.6626C9.45677 11.8302 9.86547 11.0656 10.4656 10.4654C11.0658 9.86528 11.8304 9.45658 12.6628 9.291C13.4952 9.12542 14.3581 9.2104 15.1422 9.53519C15.9263 9.85999 16.5965 10.41 17.068 11.1157C17.5396 11.8214 17.7913 12.6511 17.7913 13.4998C17.7913 14.6379 17.3391 15.7294 16.5344 16.5342C15.7296 17.3389 14.6381 17.7911 13.5 17.7911ZM17.8095 10.0943C17.6398 10.0943 17.4739 10.0439 17.3327 9.94958C17.1916 9.85525 17.0817 9.72119 17.0167 9.56436C16.9518 9.40753 16.9348 9.23497 16.968 9.0685C17.0011 8.90203 17.0829 8.74913 17.2029 8.62912C17.3229 8.50911 17.4759 8.42739 17.6424 8.39429C17.8088 8.36119 17.9814 8.3782 18.1382 8.44317C18.295 8.50813 18.429 8.61814 18.5233 8.75927C18.6176 8.90041 18.668 9.06633 18.668 9.23607C18.668 9.34879 18.6458 9.46042 18.6026 9.56456C18.5595 9.6687 18.4962 9.76332 18.4165 9.84302C18.3368 9.92272 18.2422 9.98593 18.138 10.0291C18.0339 10.0722 17.9222 10.0943 17.8095 10.0943Z"
                  fill="#E9A871"
                />
              </g>
              <defs>
                <clipPath id="clip0_86_1261">
                  <rect width="27" height="27" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="group-hover:underline inline-block mr-4">{fields.link?.title}</span>
            <svg
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="group-hover:translate-x-1 transition-transform"
            >
              <path
                d="M16.2501 7.49989C16.2446 6.90802 16.0061 6.34215 15.5863 5.92489L10.7601 1.0874C10.5493 0.877864 10.2641 0.760254 9.96694 0.760254C9.66973 0.760254 9.38459 0.877864 9.17381 1.0874C9.06837 1.19198 8.98467 1.3164 8.92756 1.4535C8.87044 1.59059 8.84104 1.73763 8.84104 1.88614C8.84104 2.03466 8.87044 2.1817 8.92756 2.31879C8.98467 2.45588 9.06837 2.58031 9.17381 2.68489L12.8751 6.37489H1.62506C1.32669 6.37489 1.04054 6.49342 0.829566 6.70439C0.618588 6.91537 0.500061 7.20152 0.500061 7.49989C0.500061 7.79826 0.618588 8.0844 0.829566 8.29538C1.04054 8.50636 1.32669 8.62489 1.62506 8.62489H12.8751L9.17381 12.3261C8.96197 12.5365 8.84237 12.8224 8.84131 13.1209C8.84026 13.4194 8.95784 13.7062 9.16819 13.918C9.37854 14.1298 9.66442 14.2495 9.96296 14.2505C10.2615 14.2516 10.5482 14.134 10.7601 13.9236L15.5863 9.08614C16.0088 8.66612 16.2475 8.09566 16.2501 7.49989V7.49989Z"
                fill="black"
                fillOpacity="0.8"
              />
            </svg>
          </CustomLosseLink>
        </div>
        {fetcher.type === 'done' && fetcher.data?.instagramFeed?.length > 0 && (
          <>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 w-full">
              {fetcher?.data?.instagramFeed?.slice(0, 6).map((item: InstagramFeedItem, index) => {
                return (
                  <a href={item.permalink || '#'} target="_blank" key={item?.id} className="hover:opacity-75col-span-1" rel="noreferrer">
                    <LossePlaatjie
                      type="fluid"
                      maxWidth={640}
                      className={clsx('w-full h-full object-cover aspect-square ')}
                      src={
                        {
                          mediaItemUrl: item.thumbnailUrl || item.mediaUrl,
                          altText: item.caption
                        } as Maybe<MediaItem>
                      }
                    />
                  </a>
                )
              })}
            </div>
          </>
        )}
      </div>
    </section>
  )
}
