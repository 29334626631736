import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ExperiencesHighlighted from './ExperiencesHighlighted'
import ExperiencesBlog from './ExperiencesBlog'
import ExperiencesRelated from './ExperiencesRelated'

export default function ExperiencesShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    highlighted: ExperiencesHighlighted,
    blog: ExperiencesBlog,
    related: ExperiencesRelated
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
