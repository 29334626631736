import { LosseBlogBink, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Pagination from '~/components/elements/Pagination'
import { useEffect } from 'react'
import NothingFound from '~/components/elements/NothingFound'

export default function VacanciesOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="VacanciesOverview">
      <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { hasNextPage, hasPrevPage } = useLosseBlogBink()
  const postTypeName = getPostTypeName(fields)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('_c')) {
      const element = document.getElementById('VacanciesOverview')

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [searchParams])

  return (
    <>
      <section data-component="BannerDefault" className="relative">
        <div className="absolute bg-[#EFF2FB] w-full h-full xl:h-[100%] top-0 left-0 -z-20">
          <svg
            className="absolute pointer-events-none w-full h-auto bottom-0 left-0 -z-10"
            width="2240"
            height="86"
            viewBox="0 0 2240 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 46.7376L93.3333 38.5283C186.667 29.6994 373.333 14.2102 560 5.38134C746.667 -2.82795 933.333 -2.82794 1120 13.7455C1306.67 29.6994 1493.33 63.7757 1680 71.5203C1866.67 79.2649 2053.33 63.7757 2146.67 54.9469L2240 46.7376V86H2146.67C2053.33 86 1866.67 86 1680 86C1493.33 86 1306.67 86 1120 86C933.333 86 746.667 86 560 86C373.333 86 186.667 86 93.3333 86H0L0 46.7376Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="container relative z-20 section">
          <div>
            <Breadcrumbs />
          </div>
          {fields?.description && (
            <div className="pt-3 sm:pt-5 lg:pb-[100px]">
              <Content className="content">{fields?.description}</Content>
            </div>
          )}
        </div>
      </section>
      <div id="VacanciesOverview" className="container section">
        <div className="h-[100px] w-[90%] mx-auto -mb-14 md:-mb-6 bg-gradient-to-b lg:rounded-[10px] rounded-[5px] from-[#E9A871] to-[#F1BD92]" />
        {fields.posts?.edges.length !== 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-8">
              {fields.posts?.edges?.map((edge) => {
                if (!edge || !edge.node) return null

                const Component = PostTypes[postTypeName] || PostTypes.Post

                return (
                  <div key={edge.node.uri} className="col-span-1">
                    <Component data={edge.node} />
                  </div>
                )
              })}
            </div>
            {(hasNextPage || hasPrevPage) && (
              <div className="flex justify-center py-6 lg:py-10">
                <Pagination />
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center">
            <NothingFound />
          </div>
        )}
      </div>
    </>
  )
}
