import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import CustomLosseLink from '~/components/elements/CustomLosseLink'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <CustomLosseLink to={data.uri || '/'}>
      <div className="bg-white relative group h-full lg:rounded-[10px] rounded-[5px] shadow-eh-primary">
        <div className="aspect-video w-full lg:rounded-[10px] rounded-[5px] overflow-hidden relative">
          <LossePlaatjie
            src={data.recap?.image ? data.recap.image : data.featuredImage?.node}
            className="w-full h-full group-hover:scale-105 smooth object-cover"
            placeholder="blur"
            maxwidth={1200}
            title={data.title}
          />
        </div>
        <div className="flex flex-col justify-between p-5 sm:p-6 lg:px-14 lg:py-8">
          <div>
            {data.recap?.readtime && (
              <div className="flex mb-4 items-center gap-3 text-black/80">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_238_1802)">
                    <path
                      d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0V0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63856 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59985 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5V16.5Z"
                      fill="#E9A871"
                    />
                    <path
                      d="M8.99984 4.5C8.80093 4.5 8.61016 4.57902 8.46951 4.71967C8.32886 4.86032 8.24984 5.05109 8.24984 5.25V8.49374L5.72159 10.0777C5.55251 10.1834 5.43232 10.3518 5.38745 10.5461C5.34258 10.7403 5.37672 10.9444 5.48234 11.1135C5.58796 11.2826 5.75642 11.4028 5.95066 11.4476C6.1449 11.4925 6.34901 11.4584 6.51809 11.3527L9.39809 9.55274C9.5069 9.48457 9.59639 9.38959 9.65798 9.27692C9.71957 9.16424 9.7512 9.03764 9.74984 8.90924V5.25C9.74984 5.05109 9.67082 4.86032 9.53017 4.71967C9.38952 4.57902 9.19875 4.5 8.99984 4.5Z"
                      fill="#E9A871"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_238_1802">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {data.recap?.readtime}
              </div>
            )}
            <h2 className="swiper-no-swiping mb-4 font-bold text-lg lg:text-2xl">{data.title}</h2>
            <Content className="children-p:h-0 first:children-p:h-auto children-p:line-clamp-2">{data.recap?.excerpt}</Content>
          </div>

          <div className="flex justify-start">
            <Button arrow className="btn mt-2 primary-link" to={data.uri}>
              Lees verder
            </Button>
          </div>
        </div>
      </div>
    </CustomLosseLink>
  )
}
