import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentWithGallery from './ContentWithGallery'
import ContentBlockWithImage from './ContentBlockWithImage'
import ContentWithImage from './ContentWithImage'
import ContentWithCta from './ContentWithCta'
import ContentWithCarousel from './ContentWithCarousel'
import ContentWithSlider from './ContentWithSlider'
import ContentWithLogoSlider from './ContentWithLogoSlider'
import ContentVideo from './ContentVideo'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    contentwithgallery: ContentWithGallery,
    contentblockwithimage: ContentBlockWithImage,
    contentwithimage: ContentWithImage,
    contentwithcta: ContentWithCta,
    contentwithcarousel: ContentWithCarousel,
    contentwithslider: ContentWithSlider,
    contentwithlogoslider: ContentWithLogoSlider,
    contentwithvideo: ContentVideo
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
