/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLoaderData, useLocation } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import CustomLosseLink from '~/components/elements/CustomLosseLink'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [isOpen, setOpen] = useState(-1)
  const location = useLocation()

  useEffect(() => {
    if (isOpen) {
      setOpen(-1)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <>
      {header?.menuHeader?.map((l, index) => {
        return (
          <motion.li onHoverStart={() => setOpen(index)} onHoverEnd={() => setOpen(-1)} className="group" key={index}>
            <div className="flex flex-wrap items-center">
              <CustomLosseLink
                className="text-xl aria-current-page:text-eh-flame-pea group-hover:text-eh-flame-pea smooth font-bold lg:text-base 2xl:text-lg"
                to={l?.link?.url || '/'}
              >
                {l?.link?.title}
              </CustomLosseLink>
            </div>
            {l?.submenu && isOpen === index && (
              <AnimatePresence>
                <HeaderSubMenu currentIndexDefault={isOpen} />
              </AnimatePresence>
            )}
          </motion.li>
        )
      })}
    </>
  )
}
