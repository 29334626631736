import type { ContentNode, Page_Flexcontent_Flex_Posts, Post as PostType } from '~/graphql/types'
import Post from '../Posts/post-types/Post'
import NothingFound from '~/components/elements/NothingFound'
import Content from '~/components/elements/Content'
import Button from '~/components/elements/Button'
import ContentWave from '~/components/elements/ContentWave'
import clsx from 'clsx'
import { useLoaderData } from '@ubo/losse-sjedel'
import { useState } from 'react'

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

export default function ExperiencesRelated({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)
  const { page } = useLoaderData<{ page: PostType }>()
  const [posts] = useState<PostType[]>([
    ...(fields.posts?.edges?.sort(() => Math.random() - 0.5).filter((f) => f.node.databaseId !== page.databaseId).slice(0, 2).map((e) => e.node) as unknown as PostType[])
  ])




  return (
    <div data-component="PostsHighlighted" className={clsx(fields.haswave && 'lg:mb-40', 'section relative')}>
      {fields.haswave && <ContentWave />}
      <div className="container md:pt-0 pt-5">
        <div className="flex flex-col gap-5 sm:gap-10 md:gap-[72px]">
          <div className="flex sm:flex-row flex-col sm:items-end md:gap-16">
            <Content className="content">{fields.description}</Content>
            {fields.link && (
              <Button arrow to={fields.link?.url} className="transparent !p-0 !font-light [&_svg>path]:!fill-black">
                {fields.link?.title}
              </Button>
            )}
          </div>
          <div>
            <div className="h-[100px] w-[90%] mx-auto -mb-14 md:-mb-6 bg-gradient-to-b lg:rounded-[10px] rounded-[5px] from-[#E9A871] to-[#F1BD92]" />
            {fields.posts?.edges.length !== 0 ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-8">
                  {posts.map((edge) => {
                    if (!edge || !edge) return null

                    const Component = PostTypes[postTypeName] || PostTypes.Post

                    return (
                      <div key={edge.uri} className="col-span-1">
                        <Component data={edge} />
                      </div>
                    )
                  })}
                </div>
              </>
            ) : (
              <div className="flex justify-center">
                <NothingFound />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
