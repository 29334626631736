import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import CustomLosseLink from '~/components/elements/CustomLosseLink'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <CustomLosseLink to={data.uri || '/'}>
      <div className="bg-white relative group h-full lg:rounded-[10px] rounded-[5px] shadow-eh-primary">
        <div className="aspect-video w-full lg:rounded-[10px] rounded-[5px] overflow-hidden relative">
          <LossePlaatjie
            src={data.recap?.image ? data.recap.image : data.featuredImage?.node}
            className="w-full h-full group-hover:scale-105 smooth object-cover"
            placeholder="blur"
            maxwidth={1200}
            title={data.title}
          />
        </div>
        <div className="flex flex-col justify-between p-5 sm:p-6 lg:px-14 lg:py-8">
          <div>
            <h2 className="swiper-no-swiping mb-4 font-bold text-lg lg:text-2xl">{data.title}</h2>
            <Content className="children-p:line-clamp-2">{data.recap?.excerpt}</Content>
          </div>

          <div className="flex justify-start">
            <Button arrow className="btn mt-2 primary-link" to={data.uri}>
              Lees verder
            </Button>
          </div>
        </div>
      </div>
    </CustomLosseLink>
  )
}
