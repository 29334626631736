import { LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import Modal from '~/components/elements/Modal'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaWithForm({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  const [formModalOpen, setFormModalOpen] = useState(false)
  const [formStatus, setFormStatus] = useState('')

  return (
    <>
      <section data-component="CtaWithForm" className="section">
        <div className="container">
          <div className="relative sm:max-lg:w-2/3 sm:max-lg:mx-auto lg:mr-16">
            <div className="absolute rounded-lg w-full lg:w-[90%] h-3/4 lg:h-full right-0 bottom-0 bg-gradient-to-t from-eh-manhattan-400 to-eh-manhattan-600"></div>
            <div className="relative grid grid-cols-9 gap-y-4 xl:gap-x-10 px-4 py-4 lg:py-6 xl:px-10">
              <div className="col-span-9 lg:col-span-3 flex justify-center lg:justify-start relative object-cover">
                <LossePlaatjie maxwidth={702} className="lg:absolute top-0 bottom-0 my-auto w-full lg:min-h-[180px]" src={fields.image} />
              </div>
              <div className="col-span-9 lg:col-span-3 xl:col-span-4 self-center">
                <Content className="content children-headings:max-lg:text-center children-headings:text-lg lg:children-headings:text-xl pr-4 lg:pl-8">
                  {fields.description}
                </Content>
              </div>
              <div className="col-span-9 lg:col-span-3 xl:col-span-2 self-center justify-self-center lg:justify-self-end">
                <Button as="button" className="text-sm px-5" onClick={() => setFormModalOpen(true)}>
                  {fields.buttonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={formModalOpen} requestClose={() => setFormModalOpen(false)}>
        {fields.description && formStatus !== 'done' && <Content className="content mb-8">{fields.form?.description}</Content>}
        <div className="relative">
          <Form
            className="form-modal-wrapper"
            generate
            data={fields.form?.data}
            privacyUrl="/privacyverklaring/"
            privacyUrlPlacement="Privacy"
            renderLoading={() => (
              <div className="absolute left-0 top-0 w-full h-full bg-white/75 z-10 flex-center">
                <Loading />
              </div>
            )}
            renderStatus={({ status, content }) => {
              setFormStatus(status)

              return <Content className="content">{content}</Content>
            }}
          />
        </div>
      </Modal>
    </>
  )
}
