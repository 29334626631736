import { LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

import Slider, { Slide } from '~/components/elements/Slider'
import Content from '~/components/elements/Content'
import { EffectCards, Mousewheel, Navigation } from 'swiper'

import Button from '~/components/elements/Button'

export default function ContentWithCarousel({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const itemLength = fields.items?.length || 0

  const initialSlideIndex = Math.floor(itemLength / 2)

  if (!fields.items) return null

  const items = fields.items

  items.push(items[0])
  items.push(items[1])
  items.push(items[2])

  return (
    <section data-component="ContentWithCarousel" className="section max-w-screen-2xl mx-auto" id={fields.sectionId ?? undefined}>
      <div className="container">
        <div className="grid grid-cols-1 text-black/80 sm:grid-cols-2">
          <div className="col-span-1 mb-4 sm:mb-0">
            <Content className="content">{fields.description}</Content>
          </div>
          <div className="col-span-1">
            <Content className="content lg:pt-2">{fields.descriptionRight}</Content>
          </div>
        </div>
      </div>
      <div className="relative sm:mx-7 mt-16 sm:mt-28">
        <div className="flex justify-center">
          <div className="absolute -top-10 w-3/4 rounded-lg h-44 bg-gradient-to-t from-eh-manhattan-400 to-eh-manhattan-600"></div>
        </div>
        <Slider
          mousewheel={{
            forceToAxis: true
          }}
          breakpoints={{
            0: {
              slidesPerView: 1.123
            },
            1024: {
              slidesPerView: 2.385
            }
          }}
          modules={[Navigation, EffectCards, Mousewheel]}
          effect="cards"
          cardsEffect={{
            perSlideRotate: 0,
            perSlideOffset: 80,
            slideShadows: false,
            rotate: true
          }}
          initialSlide={initialSlideIndex}
          centeredSlides
          loop
          loopedSlides={1}
          navigation={{
            prevEl: '.custom-contentwithcarousel-button-prev',
            nextEl: '.custom-contentwithcarousel-button-next'
          }}
          className="!py-6 sm:!py-12 three-visible"
        >
          {items.map((edge, i) => (
            <Slide key={`${edge?.link?.title}-${i}`} className="relative !h-auto">
              <div className="shadow-eh-primary rounded-[15px]">
                <div className="max-h-fit">
                  <LossePlaatjie
                    maxwidth={1000}
                    src={edge?.image}
                    className="swiper-slide-image"
                  />
                </div>
                <div className="bg-white sm:px-9 rounded-b-[15px] py-3 px-6 sm:py-4">
                  <Content className="max-xs:children-headings:text-xl content">{edge?.description}</Content>
                  <Button arrow className="transparent !font-light !text-eh-manhattan-700 mt-3" to={edge?.link?.url}>
                    {losseContentParse(edge?.link?.title)}
                  </Button>
                </div>
              </div>
            </Slide>
          )
          )}
        </Slider>
        <div className="relative flex justify-center">
          <div className="flex bg-eh-manhattan-500 rounded-full overflow-hidden">
            <button className="custom-contentwithcarousel-button-prev pl-5 pr-4 py-3 text-[0] hover:bg-eh-manhattan-400">
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180">
                <path
                  d="M1.21 0.290183C1.11627 0.383146 1.04188 0.493747 0.991108 0.615605C0.94034 0.737465 0.914202 0.868171 0.914202 1.00018C0.914202 1.13219 0.94034 1.2629 0.991108 1.38476C1.04188 1.50662 1.11627 1.61722 1.21 1.71018L5.78999 6.29018C5.88372 6.38315 5.95811 6.49375 6.00888 6.61561C6.05965 6.73747 6.08579 6.86817 6.08579 7.00018C6.08579 7.1322 6.05965 7.2629 6.00888 7.38476C5.95811 7.50662 5.88372 7.61722 5.78999 7.71018L1.21 12.2902C1.11627 12.3831 1.04188 12.4937 0.991108 12.6156C0.94034 12.7375 0.914202 12.8682 0.914202 13.0002C0.914202 13.1322 0.94034 13.2629 0.991108 13.3848C1.04188 13.5066 1.11627 13.6172 1.21 13.7102C1.39736 13.8964 1.65081 14.001 1.915 14.001C2.17918 14.001 2.43263 13.8964 2.62 13.7102L7.20999 9.12018C7.77179 8.55768 8.08735 7.79519 8.08735 7.00018C8.08735 6.20518 7.77179 5.44268 7.20999 4.88018L2.62 0.290183C2.43263 0.103932 2.17918 -0.00061129 1.915 -0.000611293C1.65081 -0.000611296 1.39736 0.103932 1.21 0.290183Z"
                  fill="white"
                />
              </svg>
              Vorige
            </button>
            <button className="pr-5 pl-4 py-3 custom-contentwithcarousel-button-next text-[0] hover:bg-eh-manhattan-400">
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.21 0.290183C1.11627 0.383146 1.04188 0.493747 0.991108 0.615605C0.94034 0.737465 0.914202 0.868171 0.914202 1.00018C0.914202 1.13219 0.94034 1.2629 0.991108 1.38476C1.04188 1.50662 1.11627 1.61722 1.21 1.71018L5.78999 6.29018C5.88372 6.38315 5.95811 6.49375 6.00888 6.61561C6.05965 6.73747 6.08579 6.86817 6.08579 7.00018C6.08579 7.1322 6.05965 7.2629 6.00888 7.38476C5.95811 7.50662 5.88372 7.61722 5.78999 7.71018L1.21 12.2902C1.11627 12.3831 1.04188 12.4937 0.991108 12.6156C0.94034 12.7375 0.914202 12.8682 0.914202 13.0002C0.914202 13.1322 0.94034 13.2629 0.991108 13.3848C1.04188 13.5066 1.11627 13.6172 1.21 13.7102C1.39736 13.8964 1.65081 14.001 1.915 14.001C2.17918 14.001 2.43263 13.8964 2.62 13.7102L7.20999 9.12018C7.77179 8.55768 8.08735 7.79519 8.08735 7.00018C8.08735 6.20518 7.77179 5.44268 7.20999 4.88018L2.62 0.290183C2.43263 0.103932 2.17918 -0.00061129 1.915 -0.000611293C1.65081 -0.000611296 1.39736 0.103932 1.21 0.290183Z"
                  fill="white"
                />
              </svg>
              Volgende
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
