import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { useLocation, useLosseLanguage } from '@ubo/losse-sjedel'
import { useDetectClickOutside } from 'react-detect-click-outside'

export const websites: {
  [key: string]: string
} = {
  nl: 'https://www.watersportcenter.nl/',
  en: 'https://www.watersportcenter.nl/online-booking-eng/',
  de: 'https://www.watersportcenter.nl/online-buchen/',
}

export default function LanguageSwitch({
  footer = false,
  className = '',
  response = true
}: {
  footer?: boolean
  className?: string
  response?: boolean
}) {
  const [showLanguages, setShowLanguages] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setShowLanguages(false) })
  let currentLocale = 'nl_NL';
  const location = useLocation()

  const languages = [
    {
      default_locale: 'nl_NL',
      translated_name: 'Nederlands',
      native_name: 'Nederlands'
    },
    {
      default_locale: 'en_US',
      translated_name: 'Engels',
      native_name: 'English'
    },
    {
      default_locale: 'de_DE',
      translated_name: 'Duits',
      native_name: 'Deutsch'
    }
  ];

  const translations = [
    {
      language: {
        default_locale: 'nl_NL',
        translated_name: 'Nederlands',
        native_name: 'Netherlands',
      },
      locale: 'nl_NL',
      href: 'https://www.watersportcenter.nl/',
    },
    {
      language: {
        default_locale: 'en_US',
        translated_name: 'Engels',
        native_name: 'English',
      },
      locale: 'en_US',
      href: 'https://www.watersportcenter.nl/online-booking-eng/',
    },
    {
      language: {
        default_locale: 'de_DE',
        translated_name: 'Duits',
        native_name: 'Deutsch',
      },
      locale: 'de_DE',
      href: 'https://www.watersportcenter.nl/online-buchen/',
    }
  ];

  if (location.pathname === '/online-booking-eng/' || location.pathname === '/online-booking-eng') {
    currentLocale = 'en_US'
  } else if (location.pathname === '/online-buchen/' || location.pathname === '/online-buchen') {
    currentLocale = 'de_DE'
  }

  function handleShowLanguages() {
    if (showLanguages) {
      setShowLanguages(false)
    }
    if (!showLanguages) {
      setShowLanguages(true)
    }
  }


  return (
    <>
      <div className="flex flex-row lg:hidden mb-6">
        {translations
          .map((language) => {
            if (!language?.href) {
              return null
            }

            return (
              <a
                key={language?.href}
                href={language?.href}
                className={clsx(
                  footer ? 'gap-3 p-1 rounded-md hover:bg-ab-tidal/30' : 'pl-1 hover:bg-ab-mist gap-2',
                  'flex w-1/6 transition-all duration-200 items-center py-1'
                )}

              >
                <div className={clsx(footer && 'w-6 h-6', response && 'w-7 h-7', !response && 'w-7 h-7', language.locale === currentLocale && 'border border-black', 'rounded-full overflow-hidden')}>
                  {flags[language?.locale || 'nl_NL'].icon}
                </div>
                <div className={clsx(!footer && 'uppercase lg:block hidden', 'text-base text-left text-ab-tidal')}>
                  {footer
                    ? languages.filter((f) => f.default_locale === language?.locale)[0].native_name
                    : flags[language?.locale || 'nl_NL']?.label}
                </div>
              </a>
            )
          })}
      </div>
      <motion.div
        className={clsx('relative hidden lg:block', className)}
        onHoverStart={() => setShowLanguages(true)}
        onHoverEnd={() => setShowLanguages(false)}
        onClick={() => handleShowLanguages()}
        role="button"
        ref={ref}
        id="LanguageSwitch"
        aria-label="Switch language"
      >
        <div>
          <div
            className={clsx(
              showLanguages && response && !footer && 'rounded-t-[5px] bg-white md:rounded-t-[5px]',
              !showLanguages && response && 'rounded-[5px] md:rounded-[5px]',
              showLanguages && !response && !footer && 'rounded-t-[5px] bg-white',
              !showLanguages && !response && 'rounded-[5px]',
              footer ? 'gap-3' : 'gap-2 p-1',
              'pointer-events-none text-ab-tidal items-center transition-all hidden md:flex'
            )}
          >
            <div className={clsx(footer && 'w-6 h-6', response && 'w-7 h-7', !response && 'w-7 h-7', 'rounded-full overflow-hidden')}>
              {flags[currentLocale].icon}
            </div>
            <div className={clsx(!footer && 'uppercase lg:block hidden', 'text-base text-left', !showLanguages && 'text-ab-tidal')}>
              {footer ? languages.filter((f) => f.default_locale === currentLocale)[0].native_name : flags[currentLocale].label}
            </div>
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={clsx(!footer && 'lg:block hidden', 'w-4', !showLanguages && 'text-ab-tidal')}
            >
              <path
                d="M4.66986 5C4.49354 5.00031 4.3189 4.96504 4.15598 4.89621C3.99305 4.82738 3.84506 4.72635 3.72049 4.59893L0.166748 0.969423L1.11611 0L4.66986 3.62882L8.22361 0L9.17297 0.969423L5.61923 4.59824C5.49472 4.72579 5.34675 4.82695 5.18382 4.89589C5.02089 4.96484 4.84622 5.00022 4.66986 5Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-row md:hidden">
          {translations
            .map((language, index) => {
              if (!language?.href) {
                return null
              }

              return (
                <a
                  key={index}
                  href={language?.href}
                  className={clsx(
                    footer ? 'gap-3 p-1 rounded-md hover:bg-ab-tidal/30' : 'pl-1 hover:bg-ab-mist gap-2',
                    'flex w-1/6 transition-all duration-200 items-center py-1'
                  )}

                >
                  <div className={clsx(footer && 'w-6 h-6', response && 'w-7 h-7', !response && 'w-7 h-7', language.locale === currentLocale && 'border border-black', 'rounded-full overflow-hidden')}>
                    {flags[language?.locale].icon}
                  </div>
                  <div className={clsx(!footer && 'uppercase lg:block hidden', 'text-base text-left text-ab-tidal')}>
                    {footer
                      ? languages.filter((f) => f.default_locale === language?.locale)[0].native_name
                      : flags[language?.locale]?.label}
                  </div>
                </a>
              )
            })}
        </div>
        <motion.div
          animate={showLanguages ? 'open' : 'closed'}
          variants={{
            closed: {
              opacity: 0,
              height: 0,
              pointerEvents: 'none'
            },
            open: {
              opacity: 1,
              height: 'auto',
              pointerEvents: 'auto'
            }
          }}
          transition={{ duration: 0.2 }}
          className={clsx(
            response && 'rounded-b-[5px] md:rounded-b-[5px]',
            !response && 'rounded-b-[5px]',
            !footer && 'bg-white lg:!bg-white',
            'h-0 opacity-0 lg:bg-transparent bg-ab-mist absolute top-[calc(100%-1px)] inset-x-0 text-ab-tidal z-10 hidden md:block'
          )}
        >
          <div className={clsx(!footer ? 'lg:py-2' : 'py-3')}>
            {translations
              .map((language, index) => {
                if (!language?.href) {
                  return null
                }

                if (language.locale === currentLocale) {
                  return null
                }

                return (
                  <a
                    key={language?.locale}
                    href={language?.href}
                    className={clsx(
                      footer ? 'gap-3 p-1 rounded-md hover:bg-ab-tidal/30' : 'pl-1 hover:bg-ab-mist gap-2',
                      'flex w-full transition-all duration-200 items-center py-1'
                    )}
                  >
                    <div className={clsx(footer && 'w-6 h-6', response && 'w-7 h-7', !response && 'w-7 h-7', 'rounded-full overflow-hidden')}>
                      {flags[language?.locale].icon}
                    </div>
                    <div className={clsx(!footer && 'uppercase lg:block hidden', 'text-base text-left text-ab-tidal')}>
                      {footer
                        ? languages.filter((f) => f.default_locale === language?.locale)[0].native_name
                        : flags[language?.locale]?.label}
                    </div>
                  </a>
                )
              })}
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}

const flags: {
  [key: string]: {
    label: string
    icon: React.ReactNode
  }
} = {
  nl_NL: {
    label: 'NL',
    icon: (
      <svg className="h-full w-full" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill="#F6F6F6"
        />
        <path
          d="M15.0001 0C8.55064 0 3.0525 4.07062 0.933105 9.78264L29.0672 9.78264C26.9477 4.07062 21.4496 0 15.0001 0Z"
          fill="#DB0432"
        />
        <path
          d="M15.0001 29.9999C21.4496 29.9999 26.9477 25.9293 29.0671 20.2173L0.933105 20.2173C3.0525 25.9293 8.55064 29.9999 15.0001 29.9999Z"
          fill="#0052B4"
        />
      </svg>
    )
  },
  en_US: {
    label: 'EN',
    icon: (
      <svg className="h-full w-full" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1580_10661)">
          <path
            d="M15.5 30C23.7843 30 30.5 23.2843 30.5 15C30.5 6.71573 23.7843 0 15.5 0C7.21573 0 0.5 6.71573 0.5 15C0.5 23.2843 7.21573 30 15.5 30Z"
            fill="#F6F6F6"
          />
          <path d="M3.60064 5.86792C2.42238 7.40091 1.53393 9.1678 1.0166 11.0873H8.82L3.60064 5.86792Z" fill="#0052B4" />
          <path d="M29.983 11.0872C29.4656 9.1678 28.5771 7.40091 27.3989 5.86792L22.1797 11.0872H29.983Z" fill="#0052B4" />
          <path d="M1.0166 18.9133C1.53398 20.8327 2.42244 22.5996 3.60064 24.1326L8.81982 18.9133H1.0166Z" fill="#0052B4" />
          <path d="M24.6324 3.10095C23.0994 1.92269 21.3326 1.03423 19.4131 0.516846V8.32019L24.6324 3.10095Z" fill="#0052B4" />
          <path d="M6.36768 26.8994C7.90066 28.0776 9.66756 28.9661 11.587 29.4835V21.6802L6.36768 26.8994Z" fill="#0052B4" />
          <path d="M11.5869 0.516846C9.6675 1.03423 7.90061 1.92269 6.36768 3.10089L11.5869 8.32013V0.516846Z" fill="#0052B4" />
          <path d="M19.4131 29.4835C21.3325 28.9661 23.0994 28.0776 24.6323 26.8994L19.4131 21.6802V29.4835Z" fill="#0052B4" />
          <path d="M22.1797 18.9133L27.3989 24.1326C28.5771 22.5997 29.4656 20.8327 29.983 18.9133H22.1797Z" fill="#0052B4" />
          <path
            d="M30.373 13.0435L17.4566 13.0435H17.4566L17.4566 0.126973C16.8161 0.0435938 16.1631 0 15.5 0C14.8368 0 14.1839 0.0435938 13.5435 0.126973L13.5435 13.0434V13.0434L0.626973 13.0434C0.543594 13.6839 0.5 14.3369 0.5 15C0.5 15.6632 0.543594 16.3161 0.626973 16.9565L13.5434 16.9565H13.5434L13.5434 29.873C14.1839 29.9564 14.8368 30 15.5 30C16.1631 30 16.8161 29.9565 17.4565 29.873V16.9566V16.9566H30.373C30.4564 16.3161 30.5 15.6632 30.5 15C30.5 14.3369 30.4564 13.6839 30.373 13.0435V13.0435Z"
            fill="#DB0432"
          />
          <path
            d="M19.4131 18.9131L26.1066 25.6067C26.4145 25.2989 26.7081 24.9772 26.9883 24.6437L21.2577 18.9131H19.4131V18.9131Z"
            fill="#DB0432"
          />
          <path
            d="M11.5871 18.9131H11.587L4.89355 25.6065C5.20129 25.9144 5.52303 26.2081 5.85654 26.4883L11.5871 20.7576V18.9131Z"
            fill="#DB0432"
          />
          <path
            d="M11.587 11.087V11.0869L4.89344 4.39331C4.58559 4.70104 4.29191 5.02278 4.01172 5.3563L9.74236 11.0869H11.587V11.087Z"
            fill="#DB0432"
          />
          <path
            d="M19.4131 11.087L26.1067 4.39338C25.7989 4.08553 25.4772 3.79186 25.1437 3.51172L19.4131 9.24236V11.087Z"
            fill="#DB0432"
          />
        </g>
        <defs>
          <clipPath id="clip0_1580_10661">
            <rect width="30" height="30" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    )
  },
  de_DE: {
    label: 'DE',
    icon: (
      <svg className="h-full w-full" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill="#CC2B1D"
        />
        <path
          d="M15.0001 0C8.55064 0 3.0525 4.07062 0.933105 9.78264L29.0672 9.78264C26.9477 4.07062 21.4496 0 15.0001 0Z"
          fill="#000000"
        />
        <path
          d="M15.0001 29.9999C21.4496 29.9999 26.9477 25.9293 29.0671 20.2173L0.933105 20.2173C3.0525 25.9293 8.55064 29.9999 15.0001 29.9999Z"
          fill="#F8D147"
        />
      </svg>
    )
  }
}
