import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Autoplay } from 'swiper'
import Content from '~/components/elements/Content'
import CustomLosseLink from '~/components/elements/CustomLosseLink'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithLogoSlider({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section key={fields.sliderId} data-component="ContentWithLogoSlider" className="section relative">
      <div className="container">
        <Content className="content mb-8">{fields.description}</Content>
        <div className="relative">
          <div className="absolute z-10 top-0 h-full -left-4 bg-gradient-to-r from-white w-28 lg:w-48 pointer-events-none" />
          <div className="absolute z-10 top-0 h-full -right-4 bg-gradient-to-l from-white w-28 lg:w-48 pointer-events-none" />
          <Slider
            centerInsufficientSlides
            modules={[Autoplay]}
            autoplay={{
              delay: 3000
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 15
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 25
              },
              820: {
                slidesPerView: 4,
                spaceBetween: 35
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: Number(fields.items?.length) > 3 ? 50 : 100
              }
            }}
          >
            {fields.items?.map((edge) => {
              return (
                <Slide key={edge?.link?.title} className="!h-auto">
                  {edge?.link?.url === '#' ? (
                    <div className="relative flex h-full justify-center items-center hover:opacity-75 transition-opacity">
                      <LossePlaatjie maxwidth={500} src={edge?.image} alt="" className="max-sm:h-10 w-full object-contain" />
                      <span className="absolute opacity-0 pointer-events-none top-0">{edge?.link?.title}</span>
                    </div>
                  ) : (
                    <CustomLosseLink
                      to={edge?.link?.url || '/'}
                      className="relative flex h-full justify-center items-center hover:opacity-75 transition-opacity"
                    >
                      <LossePlaatjie maxwidth={500} src={edge?.image} alt="" className="max-sm:h-10 w-full object-contain" />
                      <span className="absolute opacity-0 pointer-events-none top-0">{edge?.link?.title}</span>
                    </CustomLosseLink>
                  )}
                </Slide>
              )
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}
