import { useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import ChildLink from '~/components/elements/ChildLink'
import Content from '~/components/elements/Content'
import ContentWave from '~/components/elements/ContentWave'
import NothingFound from '~/components/elements/NothingFound'
import type { Page, Page_Flexcontent_Flex_Misc } from '~/graphql/types'

export default function MiscChildLinks({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const { page } = useLoaderData<{ page: Page }>()

  return (
    <div
      data-component="MiscChildLinks"
      id={fields.sectionId ?? undefined}
      className={clsx(fields.haswave && 'lg:mb-40', 'section relative')}
    >
      {fields.haswave && <ContentWave />}
      <div className="container md:pt-0 pt-5">
        <div className="flex flex-col gap-5 sm:gap-10 md:gap-[72px]">
          <div className="flex sm:flex-row flex-col sm:items-center md:gap-16">
            <Content className="content text-black/80">{fields.description}</Content>
            {fields.link && (
              <div>
                <Button arrow className="btn primary-bare" to={fields.link?.url}>
                  {fields.link?.title}
                </Button>
              </div>
            )}
          </div>
          <div>
            <div className="h-[100px] w-[90%] mx-auto -mb-14 md:-mb-6 bg-gradient-to-b lg:rounded-[10px] rounded-[5px] from-[#E9A871] to-[#F1BD92]" />
            {page.children?.nodes.length !== 0 ? (
              <>
                <div
                  className={clsx(
                    (page.children?.nodes.length || 0) >= 5 ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1 md:grid-cols-2',
                    'grid gap-5 lg:gap-8'
                  )}
                >
                  {page.children?.nodes
                    .filter((edge) => {
                      const page = edge as Page
                      return edge && page.title
                    })
                    // @ts-ignore
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((edge) => {
                      const page = edge as Page
                      return (
                        <div key={page.uri} className="col-span-1">
                          <ChildLink data={page} readmore={fields.readmore || ''} />
                        </div>
                      )
                    })}
                </div>
              </>
            ) : (
              <div className="flex justify-center">
                <NothingFound />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
