import { type PropsWithChildren } from 'react'
import { LosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Header />
      <div className="bg-[#286e99] fixed -right-[4.5rem] lg:-right-20 -rotate-90 rounded-t-xl p-4 z-50 top-40 lg:top-80">
        <a href="https://www.eemhof-winterlodge.nl/" target="_blank" rel="noreferrer" className="mb-0 text-white font-bold">
          Eemhof Winterlodge
        </a>
      </div>
      <main className="min-h-[calc(100vh_-_130px)]">{children}</main>
      <Footer />
    </LosseLayout>
  )
}
