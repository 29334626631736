import { motion } from 'framer-motion'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import { type HeaderLoaderData } from './Header'
import { useState } from 'react'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import CustomLosseLink from '~/components/elements/CustomLosseLink'

export default function HeaderSubmenu({ currentIndexDefault }: { currentIndexDefault: number }) {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [currentIndex, setCurrentIndex] = useState(currentIndexDefault)

  return (
    <motion.ul
      className="relative w-full left-0 overflow-hidden lg:absolute"
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
    >
      <div className="lg:pt-5">
        <div className="px-14 py-12 grid gap-14 grid-cols-5 lg:rounded-[10px] lg:bg-gradient-to-b from-[#E9A871] to-[#F1BD92] lg:shadow-2xl">
          <div className="flex flex-col gap-11 py-5 relative">
            <div className="bg-white h-full w-[1px] top-0 right-0 absolute" />
            {header.menuHeader?.map((l, lIndex) => {
              return (
                <motion.li onHoverStart={() => setCurrentIndex(lIndex)} key={lIndex}>
                  <CustomLosseLink
                    className={clsx(lIndex === currentIndex ? 'text-eh-flame-pea' : 'text-black', 'text-lg smooth font-bold')}
                    to={l?.link?.url || '/'}
                  >
                    {l?.link?.title}
                  </CustomLosseLink>
                </motion.li>
              )
            })}
          </div>
          <div className="flex col-span-2 gap-3 flex-col py-5">
            {header.menuHeader![currentIndex]?.submenu?.map((f, index: number) => {
              return (
                <li className="list-none" key={index}>
                  <CustomLosseLink to={f?.link?.url || '/'} className="text-black hover:text-eh-flame-pea smooth font-normal text-lg">
                    <Content>{f?.link?.title}</Content>
                  </CustomLosseLink>
                </li>
              )
            })}
          </div>
          <div className="col-span-2">
            <div className="bg-white relative h-[400px] flex flex-col justify-between rounded-[5px]">
              <LossePlaatjie
                maxwidth={1400}
                className="rounded-t-[5px] h-[190px] object-cover"
                src={header.menuHeader![currentIndex]?.cta?.image}
                alt="service-image"
              />
              <svg
                className="absolute w-full h-auto left-0 top-[170px] z-10"
                width="490"
                height="97"
                viewBox="0 0 490 97"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 10.1838L20.4167 8.39506C40.8333 6.47131 81.6667 3.09631 122.5 1.17256C163.333 -0.616191 204.167 -0.61619 245 2.99506C285.833 6.47131 326.667 13.8963 367.5 15.5838C408.333 17.2713 449.167 13.8963 469.583 11.9726L490 10.1838V96.5838H469.583C449.167 96.5838 408.333 96.5838 367.5 96.5838C326.667 96.5838 285.833 96.5838 245 96.5838C204.167 96.5838 163.333 96.5838 122.5 96.5838C81.6667 96.5838 40.8333 96.5838 20.4167 96.5838H0V10.1838Z"
                  fill="white"
                />
              </svg>
              <div className="relative h-full">
                <div className="px-12 pb-7 h-full flex flex-col justify-between relative ">
                  <Content className="content relative z-20">{header.menuHeader![currentIndex]?.cta?.description}</Content>
                  <div>
                    <Button className="primary-bare" arrow to={header.menuHeader![currentIndex]?.cta?.link?.url || '/'}>
                      <Content>{header.menuHeader![currentIndex]?.cta?.link?.title}</Content>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.ul>
  )
}
