export default function ContentWave() {
  return (
    <svg
      className="absolute left-0 w-full h-auto -bottom-[50%] lg:-bottom-[300px] -z-10"
      width="2240"
      height="898"
      viewBox="0 0 2240 898"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2240" height="563" fill="url(#paint0_linear_334_1145)" />
      <g clipPath="url(#clip0_334_1145)">
        <path
          d="M0 499.6L93.3333 491.352C186.667 482.481 373.333 466.919 560 458.048C746.667 449.8 933.333 449.8 1120 466.452C1306.67 482.481 1493.33 516.719 1680 524.5C1866.67 532.281 2053.33 516.719 2146.67 507.848L2240 499.6V898H2146.67C2053.33 898 1866.67 898 1680 898C1493.33 898 1306.67 898 1120 898C933.333 898 746.667 898 560 898C373.333 898 186.667 898 93.3333 898H0V499.6Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip1_334_1145)">
        <path
          d="M0 361H93.3333C186.667 361 373.333 361 560 385.8C746.667 410.6 933.333 460.2 1120 443.615C1306.67 427.65 1493.33 343.95 1680 319.615C1866.67 294.35 2053.33 328.45 2146.67 344.415L2240 361V609H2146.67C2053.33 609 1866.67 609 1680 609C1493.33 609 1306.67 609 1120 609C933.333 609 746.667 609 560 609C373.333 609 186.667 609 93.3333 609H0V361Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_334_1145" x1="1120" y1="563" x2="1120" y2="-8.96225e-06" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFF2FB" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_334_1145">
          <rect width="2240" height="498" fill="white" transform="translate(0 400)" />
        </clipPath>
        <clipPath id="clip1_334_1145">
          <rect width="2240" height="496" fill="white" transform="translate(0 113)" />
        </clipPath>
      </defs>
    </svg>
  )
}
