import { useLoaderData, useLosseZoekenMoek } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Misc } from '~/graphql/types'
import { type LoaderData } from '@ubo/losse-sjedel'
import { Form } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import type { Page, Post } from '~/graphql/types'
import CustomLosseLink from '~/components/elements/CustomLosseLink'

export default function SearchResults({ fields }: { fields: Page_Flexcontent_Flex_Misc }) {
  const data = useLoaderData<LoaderData>()
  const [state, result, currentValue, next, prev] = useLosseZoekenMoek()

  const contentTypeNames: {
    [key: string]: string
  } = {
    employee: 'Medewerker',
    ervaringen: 'Ervaring',
    activity: 'Activiteit',
    page: 'Pagina',
    post: 'Nieuws'
  }

  return (
    <section data-component="SearchResults" className="section">
      <div className="container relative z-10 pb-20 xl:max-w-screen-xl">
        <div className="flex justify-center">
          <Form
            action={new URL(data.request.url).pathname}
            method="get"
            className="flex items-center overflow-hidden rounded-full shadow-lg min-w-[272px]"
          >
            <input
              type="search"
              name="q"
              placeholder={fields.searchfields?.placeholdertext || 'Typ hier om te zoeken'}
              defaultValue={currentValue}
              className="md:w-72 rounded-none py-2 pl-6 pr-3"
            />
            <button type="submit" className="bg-eh-scampi block h-full text-white font-bold rounded-l-none py-2 px-4 md:px-6">
              <span className="hidden md:block">{fields.searchfields?.buttontext}</span>
              <svg className="md:hidden" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5" clipPath="url(#clip0_38_608)">
                  <path
                    d="M19.7557 18.5781L14.7815 13.6039C16.137 11.946 16.8035 9.83063 16.643 7.69519C16.4826 5.55974 15.5075 3.56766 13.9195 2.13098C12.3315 0.69431 10.252 -0.0770391 8.11119 -0.0235126C5.9704 0.030014 3.93207 0.90432 2.41783 2.41856C0.903588 3.9328 0.0292815 5.97113 -0.024245 8.11192C-0.0777715 10.2527 0.693577 12.3322 2.13025 13.9202C3.56693 15.5082 5.55901 16.4833 7.69445 16.6438C9.8299 16.8042 11.9453 16.1377 13.6032 14.7822L18.5773 19.7564C18.7345 19.9082 18.945 19.9922 19.1635 19.9903C19.382 19.9884 19.591 19.9007 19.7455 19.7462C19.9 19.5917 19.9877 19.3827 19.9895 19.1642C19.9914 18.9457 19.9075 18.7352 19.7557 18.5781ZM8.33315 15.0006C7.01461 15.0006 5.72568 14.6096 4.62935 13.877C3.53302 13.1445 2.67854 12.1033 2.17395 10.8851C1.66937 9.66693 1.53735 8.32649 1.79458 7.03328C2.05182 5.74008 2.68676 4.55219 3.61911 3.61984C4.55146 2.68749 5.73934 2.05255 7.03255 1.79532C8.32576 1.53808 9.6662 1.6701 10.8844 2.17469C12.1025 2.67927 13.1437 3.53375 13.8763 4.63008C14.6088 5.72641 14.9998 7.01534 14.9998 8.33389C14.9978 10.1014 14.2948 11.7959 13.045 13.0457C11.7952 14.2956 10.1007 14.9986 8.33315 15.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_38_608">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </Form>
        </div>

        {(state === 'loading' || state === 'submitting') && !!currentValue && (
          <div className="flex justify-center py-10">
            <Loading />
          </div>
        )}

        <div className="mt-10">
          {state === 'idle' && (
            <>
              {result?.edges?.length === 0 && <Content className="content">{fields.searchfields?.noresultstext}</Content>}
              <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 sm:gap-6 lg:grid-cols-3 lg:gap-10">
                {result?.edges?.map((edge) => {
                  if (!edge) return null
                  if (!edge.node) return null
                  const node = edge.node as Post | Page

                  return (
                    <CustomLosseLink
                      to={edge.node.uri || '/'}
                      key={node.id}
                      className="group col-span-1 flex flex-col justify-between overflow-hidden rounded-xl bg-white shadow-lg"
                    >
                      <div className="p-3 lg:p-5">
                        <div className="mb-2 block text-xl font-extrabold line-clamp-2 group-hover:underline lg:text-2xl">{node.title}</div>
                        <div className="flex items-end justify-between">
                          <div className="mt-2 rounded-full bg-eh-scampi px-3 py-1 text-[12px] font-bold uppercase text-white">
                            {contentTypeNames[node.contentTypeName] || node.contentTypeName}
                          </div>
                          <div className="flex h-8 w-8 items-center justify-center rounded-full lg:h-10 lg:w-10">
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.4167 8.73656C20.4096 7.96933 20.1004 7.23579 19.5562 6.6949L13.3 0.424073C13.0268 0.152457 12.6571 0 12.2719 0C11.8866 0 11.517 0.152457 11.2437 0.424073C11.1071 0.559643 10.9986 0.720936 10.9245 0.898648C10.8505 1.07636 10.8124 1.26697 10.8124 1.45949C10.8124 1.65201 10.8505 1.84262 10.9245 2.02033C10.9986 2.19804 11.1071 2.35933 11.2437 2.4949L16.0417 7.27823H1.45833C1.07156 7.27823 0.700627 7.43188 0.427136 7.70537C0.153646 7.97886 0 8.34979 0 8.73656C0 9.12334 0.153646 9.49427 0.427136 9.76776C0.700627 10.0412 1.07156 10.1949 1.45833 10.1949H16.0417L11.2437 14.9928C10.9691 15.2655 10.8141 15.6361 10.8127 16.0231C10.8114 16.4101 10.9638 16.7817 11.2365 17.0563C11.5091 17.331 11.8797 17.486 12.2667 17.4874C12.6537 17.4887 13.0254 17.3363 13.3 17.0636L19.5562 10.7928C20.104 10.2483 20.4134 9.50886 20.4167 8.73656Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </CustomLosseLink>
                  )
                })}
              </div>
            </>
          )}
        </div>

        <div className="mt-10 flex justify-end">
          <button
            type="button"
            className={'btn--red ml-3 flex h-8 w-8 items-center justify-center rounded-full disabled:opacity-25 lg:h-12 lg:w-12'}
            disabled={!result?.pageInfo?.hasPreviousPage}
            onClick={() => {
              prev()
            }}
          >
            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
              <path
                d="M1.66694e-06 5.5C-0.000374548 5.28465 0.041951 5.07135 0.124546 4.87236C0.207141 4.67336 0.328377 4.4926 0.481285 4.34046L4.83669 -5.08498e-08L6 1.15953L1.64541 5.5L6 9.84046L4.83669 11L0.482108 6.65953C0.329051 6.50746 0.207664 6.32673 0.124926 6.12774C0.0421889 5.92874 -0.000266325 5.71541 1.66694e-06 5.5Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            type="button"
            className={'btn--red ml-3 flex h-8 w-8 items-center justify-center rounded-full disabled:opacity-25 lg:h-12 lg:w-12'}
            disabled={!result?.pageInfo?.hasNextPage}
            onClick={() => {
              next()
            }}
          >
            <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
              <path
                d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  )
}
