import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Sun from '~/components/elements/Sun'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentBlockWithImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section className="section" data-component="ContentBlockWithImage">
      <div className="container relative">
        <Sun className="absolute right-0 sm:bottom-[unset] bottom-0 lg:z-10" />

        <div className="max-w-screen-xl mx-auto grid sm:gap-0 gap-5 grid-cols-2 sm:grid-cols-3">
          <div className="relative lg:z-30 col-span-2 flex items-center">
            <div className="bg-white flex flex-col gap-6 sm:gap-10 sm:mb-0 -mb-14 sm:-mr-6 lg:-mr-14 shadow-eh-primary z-10 xl:z-20 relative p-6 pb-20 sm:p-12 lg:px-[70px] lg:py-16 rounded-[5px] lg:rounded-[10px]">
              <Content className="content">{fields.description}</Content>
              {fields.links && (
                <div className="flex items-center gap-2">
                  {fields.links.map((link, index) => (
                    <Button arrow className={clsx(index >= 1 && 'secondary', 'btn')} key={index} to={link?.link?.url || '/'}>
                      {link?.link?.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 relative flex justify-center items-center">
            <LossePlaatjie
              maxwidth={1000}
              src={fields.image}
              alt="content-block-image"
              className="rounded-[5px] h-[350px] md:h-[472px] relative lg:z-20 sm:max-w-full max-w-[350px] lg:rounded-[10px] object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
