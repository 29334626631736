import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="pb-6 lg:pb-20 relative">
      <div className="absolute bg-[#EFF2FB] w-full h-full bottom-0 left-0 -z-20" />

      <svg
        className="absolute pointer-events-none w-full h-auto bottom-0 left-0 -z-10"
        width="2240"
        height="86"
        viewBox="0 0 2240 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 46.7376L93.3333 38.5283C186.667 29.6994 373.333 14.2102 560 5.38134C746.667 -2.82795 933.333 -2.82794 1120 13.7455C1306.67 29.6994 1493.33 63.7757 1680 71.5203C1866.67 79.2649 2053.33 63.7757 2146.67 54.9469L2240 46.7376V86H2146.67C2053.33 86 1866.67 86 1680 86C1493.33 86 1306.67 86 1120 86C933.333 86 746.667 86 560 86C373.333 86 186.667 86 93.3333 86H0L0 46.7376Z"
          fill="white"
        />
      </svg>

      <div className="container relative z-20 section">
        <div>
          <Breadcrumbs />
        </div>
        {fields?.description && (
          <div className="pt-3 sm:pt-5">
            <Content className="content">{fields?.description}</Content>
          </div>
        )}
      </div>
    </section>
  )
}
