import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import ContentWave from '~/components/elements/ContentWave'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section className={clsx(fields.haswave && 'lg:mb-20', 'section relative')} data-component="ContentWithImage">
      {fields.haswave && <ContentWave />}
      <div className="container">
        <div className="max-w-[1330px] mx-auto grid gap-8 lg:gap-24 col-span-4 lg:grid-cols-7">
          <div className="col-span-4 lg:col-span-3 flex items-center">
            <LossePlaatjie maxwidth={800} src={fields.image} className="rounded-lg md:h-[370px] object-cover" />
          </div>
          <div className={clsx(fields.flipColumns && 'lg:order-first', 'col-span-4 flex gap-4 lg:gap-10 flex-col justify-center')}>
            <Content className="content">{fields.description}</Content>
            {fields.links && (
              <div className="flex items-center gap-2">
                {fields.links.map((link, index) => (
                  <Button
                    arrow
                    className={clsx(index >= 1 && 'secondary', 'btn')}
                    key={index}
                    to={link?.link?.url || '/'}
                    target={link?.link?.target ?? undefined}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
