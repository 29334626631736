import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ActivitiesOverview from './ActivitiesOverview'
import ActivitiesHighlighted from './ActivitiesHighlighted'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: ActivitiesOverview,
    highlighted: ActivitiesHighlighted
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
