import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Navigation } from 'swiper'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import ContentWave from '~/components/elements/ContentWave'
import CustomLosseLink from '~/components/elements/CustomLosseLink'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithSlider({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section key={fields.sliderId} data-component="ContentWithSlider" className="section relative">
      {fields.haswave && <ContentWave />}
      <div className="max-w-screen-2xl mx-auto">
        <div className="pl-4 sm:mx-7 lg:pl-32">
          <div className="grid grid-cols-5 gap-x-4">
            <div className="col-span-5 lg:col-span-2">
              {fields.description && <Content className="content lg:pt-24 lg:[&_p]:pr-28">{fields.description}</Content>}
            </div>
            <div className="col-span-5 lg:col-span-3">
              <div>
                <Slider
                  modules={[Navigation]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5
                    },
                    640: {
                      slidesPerView: 2
                    }
                  }}
                  navigation={{
                    prevEl: `.custom-contentwithslider-${fields.sliderId}-button-prev`,
                    nextEl: `.custom-contentwithslider-${fields.sliderId}-button-next`
                  }}
                  className="!py-4"
                >
                  {fields.items?.map((edge, index) => {
                    return (
                      <Slide key={`${edge?.link?.title}-${index}`} className="!h-auto px-3 lg:px-5">
                        <div className="shadow-eh-primary-s xs:shadow-eh-primary bg-white rounded-[10px] lg:rounded-[15px] overflow-hidden h-full flex flex-col justify-between">
                          <div>
                            <CustomLosseLink to={edge?.link?.url || '/'}>
                              <div className="relative h-[150px] xs:h-[200px] md:h-[250px] w-full group overflow-hidden rounded-[10px] lg:rounded-[15px]">
                                <LossePlaatjie
                                  maxwidth={800}
                                  src={edge?.image}
                                  className="group-hover:scale-105 smooth object-cover h-full w-full"
                                />
                              </div>
                            </CustomLosseLink>
                            <div className="lg:px-7 lg:py-4 px-4 py-3">
                              <Content className="content max-xs:children-h3:text-xl">{edge?.description}</Content>
                            </div>
                          </div>
                          <div className="lg:px-7 lg:py-4 px-4 py-3">
                            <Button to={edge?.link?.url} arrow className="transparent !text-eh-manhattan-700 !font-light mt-4">
                              {edge?.link?.title}
                            </Button>
                          </div>
                        </div>
                      </Slide>
                    )
                  })}
                </Slider>
              </div>

              <div className="flex justify-start lg:ml-6 lg:mt-3">
                <div className="flex bg-eh-manhattan-500 rounded-full overflow-hidden">
                  <button className={`custom-contentwithslider-${fields.sliderId}-button-prev pl-5 pr-4 py-3 hover:bg-eh-manhattan-400`}>
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="rotate-180">
                      <path
                        d="M1.21 0.290183C1.11627 0.383146 1.04188 0.493747 0.991108 0.615605C0.94034 0.737465 0.914202 0.868171 0.914202 1.00018C0.914202 1.13219 0.94034 1.2629 0.991108 1.38476C1.04188 1.50662 1.11627 1.61722 1.21 1.71018L5.78999 6.29018C5.88372 6.38315 5.95811 6.49375 6.00888 6.61561C6.05965 6.73747 6.08579 6.86817 6.08579 7.00018C6.08579 7.1322 6.05965 7.2629 6.00888 7.38476C5.95811 7.50662 5.88372 7.61722 5.78999 7.71018L1.21 12.2902C1.11627 12.3831 1.04188 12.4937 0.991108 12.6156C0.94034 12.7375 0.914202 12.8682 0.914202 13.0002C0.914202 13.1322 0.94034 13.2629 0.991108 13.3848C1.04188 13.5066 1.11627 13.6172 1.21 13.7102C1.39736 13.8964 1.65081 14.001 1.915 14.001C2.17918 14.001 2.43263 13.8964 2.62 13.7102L7.20999 9.12018C7.77179 8.55768 8.08735 7.79519 8.08735 7.00018C8.08735 6.20518 7.77179 5.44268 7.20999 4.88018L2.62 0.290183C2.43263 0.103932 2.17918 -0.00061129 1.915 -0.000611293C1.65081 -0.000611296 1.39736 0.103932 1.21 0.290183Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                  <button className={`custom-contentwithslider-${fields.sliderId}-button-next pr-5 pl-4 py-3 hover:bg-eh-manhattan-400`}>
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.21 0.290183C1.11627 0.383146 1.04188 0.493747 0.991108 0.615605C0.94034 0.737465 0.914202 0.868171 0.914202 1.00018C0.914202 1.13219 0.94034 1.2629 0.991108 1.38476C1.04188 1.50662 1.11627 1.61722 1.21 1.71018L5.78999 6.29018C5.88372 6.38315 5.95811 6.49375 6.00888 6.61561C6.05965 6.73747 6.08579 6.86817 6.08579 7.00018C6.08579 7.1322 6.05965 7.2629 6.00888 7.38476C5.95811 7.50662 5.88372 7.61722 5.78999 7.71018L1.21 12.2902C1.11627 12.3831 1.04188 12.4937 0.991108 12.6156C0.94034 12.7375 0.914202 12.8682 0.914202 13.0002C0.914202 13.1322 0.94034 13.2629 0.991108 13.3848C1.04188 13.5066 1.11627 13.6172 1.21 13.7102C1.39736 13.8964 1.65081 14.001 1.915 14.001C2.17918 14.001 2.43263 13.8964 2.62 13.7102L7.20999 9.12018C7.77179 8.55768 8.08735 7.79519 8.08735 7.00018C8.08735 6.20518 7.77179 5.44268 7.20999 4.88018L2.62 0.290183C2.43263 0.103932 2.17918 -0.00061129 1.915 -0.000611293C1.65081 -0.000611296 1.39736 0.103932 1.21 0.290183Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
