/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocation, useLosseLayout, useNavigate, Form } from '@ubo/losse-sjedel'
import TopHeaderMenu from './TopHeaderMenu'
import HeaderMenuMobile from './HeaderMenuMobile'

export default function HeaderMobile() {
  const { setScrollable } = useLosseLayout()
  const location = useLocation()
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen) {
      setOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  useEffect(() => {
    const { documentElement } = document
    if (isOpen) {
      documentElement.classList.add('overflow-hidden')
    } else {
      documentElement.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        onClick={() => {
          setOpen(!isOpen)
          setScrollable(false)
        }}
        className="z-10 h-12 w-8 text-center"
        title="Open menu"
      >
        <div className="mx-auto my-[5px] h-[2px] w-full bg-black" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-black" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-black" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            exit={{ x: '-100vw' }}
            transition={{ bounce: 0 }}
            className="fixed left-0 top-0 z-50 flex inset-0 items-center justify-center bg-gradient-to-t from-eh-manhattan-400 to-eh-manhattan-600"
          >
            <div
              className="absolute z-[99] right-7 top-7 text-center"
              role="button"
              onClick={() => {
                setOpen(!isOpen)

                setScrollable(true)
              }}
              aira-label="Close menu"
            >
              <div className="relative ml-4 h-5 w-5 before:absolute before:h-[24px] before:w-[2px] before:rotate-45 before:bg-black after:absolute after:h-[24px] after:w-[2px] after:-rotate-45 after:bg-black" />
            </div>
            <div className="h-4/5 w-9/12 flex flex-col justify-between pt-12">
              <ul className="m-0 pl-0">
                <HeaderMenuMobile />
                <TopHeaderMenu />
              </ul>
              <Form>
                <div className="relative max-w-[300px]">
                  <input
                    id="mobile-search-input"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()

                        navigate(`/zoeken?q=${e.currentTarget.value}`)
                      }
                    }}
                    placeholder="Zoeken"
                    className="w-full px-6 h-12 rounded-full"
                  />
                  <svg
                    className="absolute top-0 bottom-0 my-auto right-6"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="button"
                    onClick={() => {
                      const msi = document.getElementById('mobile-search-input') as HTMLInputElement
                      navigate(`/zoeken?q=${msi?.value}`)
                    }}
                  >
                    <g opacity="0.5" clipPath="url(#clip0_38_608)">
                      <path
                        d="M19.7557 18.5781L14.7815 13.6039C16.137 11.946 16.8035 9.83063 16.643 7.69519C16.4826 5.55974 15.5075 3.56766 13.9195 2.13098C12.3315 0.69431 10.252 -0.0770391 8.11119 -0.0235126C5.9704 0.030014 3.93207 0.90432 2.41783 2.41856C0.903588 3.9328 0.0292815 5.97113 -0.024245 8.11192C-0.0777715 10.2527 0.693577 12.3322 2.13025 13.9202C3.56693 15.5082 5.55901 16.4833 7.69445 16.6438C9.8299 16.8042 11.9453 16.1377 13.6032 14.7822L18.5773 19.7564C18.7345 19.9082 18.945 19.9922 19.1635 19.9903C19.382 19.9884 19.591 19.9007 19.7455 19.7462C19.9 19.5917 19.9877 19.3827 19.9895 19.1642C19.9914 18.9457 19.9075 18.7352 19.7557 18.5781ZM8.33315 15.0006C7.01461 15.0006 5.72568 14.6096 4.62935 13.877C3.53302 13.1445 2.67854 12.1033 2.17395 10.8851C1.66937 9.66693 1.53735 8.32649 1.79458 7.03328C2.05182 5.74008 2.68676 4.55219 3.61911 3.61984C4.55146 2.68749 5.73934 2.05255 7.03255 1.79532C8.32576 1.53808 9.6662 1.6701 10.8844 2.17469C12.1025 2.67927 13.1437 3.53375 13.8763 4.63008C14.6088 5.72641 14.9998 7.01534 14.9998 8.33389C14.9978 10.1014 14.2948 11.7959 13.045 13.0457C11.7952 14.2956 10.1007 14.9986 8.33315 15.0006Z"
                        fill="black"
                        fillOpacity="0.8"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_38_608">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </Form>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
