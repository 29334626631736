import HeaderMenu from './HeaderMenu'
import TopHeaderMenu from './TopHeaderMenu'

export default function HeaderDesktop() {
  return (
    <div className="inline-flex flex-col gap-2 2xl:gap-4">
      <ul className="mb-0 gap-8 xl:gap-14 2xl:gap-20 hidden w-full list-none items-center lg:flex">
        <TopHeaderMenu />
      </ul>
      <div className="">
        <ul className="mb-0 gap-6 xl:gap-14 2xl:gap-20 w-fit bg-white px-14 py-2 rounded-full hidden list-none items-center lg:flex">
          <HeaderMenu />
        </ul>
      </div>
    </div>
  )
}
