import { LossePlaatjie } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import WaveOrange from '~/components/elements/BannerWave'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import CustomLosseLink from '~/components/elements/CustomLosseLink'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerLanding" className="max-w-[2000px] relative mx-auto xl:mb-24">
      <div className="relative">
        {fields?.videoUrl && (
          <video loop muted autoPlay playsInline className="w-[calc(100%-1px)] h-auto lg:h-[570px] 2xl:h-[700px] object-cover aspect-video">
            <source src={fields.videoUrl} type="video/mp4" />
          </video>
        )}
        <div className="relative md:absolute right-0 left-0 h-full md:bg-gradient-to-r md:from-eh-amethyst-smoke-900 via-transparent -mt-16 top-0 md:-mt-0">
          <WaveOrange className="md:hidden" />
          <div className="-mt-8 container flex flex-col justify-center h-full mb-4 md:mb-0">
            <Content className="content lg:children-h1:text-5xl 2xl:children-h1:text-6xl md:text-white lg:w-4/5 xl:[&_p]:pr-80">
              {fields.description}
            </Content>
            <div className="flex flex-wrap mt-9">
              {fields.links?.map((edge) => {
                return (
                  <Button arrow key={edge?.link?.title} to={edge?.link?.url} className="md:even:white-outline mr-6 mb-4">
                    {edge?.link?.title}
                  </Button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-0 md:-mt-12 lg:-mt-24 pb-8 xl:pb-44">
        <WaveOrange className="max-md:hidden absolute top-0 left-0 w-full h-auto" />
        <div className="xl:absolute relative mt-0 md:-mt-4 lg:top-0 left-0 right-0">
          <div className="container grid xs:grid-cols-2 xl:grid-cols-4 gap-5">
            {fields.linkBlocks?.map((edge) => {
              return (
                <CustomLosseLink
                  to={edge?.link?.url || '/'}
                  key={edge?.link?.title}
                  className="relative group col-span-1 shadow-lg rounded-xl overflow-hidden aspect-square"
                >
                  <LossePlaatjie
                    maxWidth={2000}
                    src={edge?.image}
                    className="aspect-video object-cover group-hover:scale-105 transition-transform"
                  />

                  <div className="absolute bottom-0 left-0 right-0 h-2/5 sm:h-1/2 bg-white">
                    <div className="relative w-full h-full">
                      <svg
                        className="absolute w-full h-auto left-0 -top-4"
                        width="490"
                        height="97"
                        viewBox="0 0 490 97"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 10.1838L20.4167 8.39506C40.8333 6.47131 81.6667 3.09631 122.5 1.17256C163.333 -0.616191 204.167 -0.61619 245 2.99506C285.833 6.47131 326.667 13.8963 367.5 15.5838C408.333 17.2713 449.167 13.8963 469.583 11.9726L490 10.1838V96.5838H469.583C449.167 96.5838 408.333 96.5838 367.5 96.5838C326.667 96.5838 285.833 96.5838 245 96.5838C204.167 96.5838 163.333 96.5838 122.5 96.5838C81.6667 96.5838 40.8333 96.5838 20.4167 96.5838H0V10.1838Z"
                          fill="white"
                        />
                      </svg>
                      <div className="relative pb-6 px-2 sm:px-6">
                        <Button
                          arrow
                          className="transparent group-hover:[&>svg]:translate-x-1 group-hover:underline max-w-full inline-flex flex-wrap"
                          to={edge?.link?.url}
                        >
                          <span className="text-base xs:text-sm md:text-base">{edge?.link?.title}</span>
                        </Button>
                        <Content>{edge?.recap}</Content>
                      </div>
                    </div>
                  </div>
                </CustomLosseLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
