import { useLocation } from '@ubo/losse-sjedel';
import { useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';
import Content from '~/components/elements/Content';
import CustomLosseLink from '~/components/elements/CustomLosseLink';
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types';

const WIDGET_LIST_VIEWS = ['0V39uix12Dns0dP1PJHhT6Kw1r07do17736OXf41y3Ed9C47iW', 'fabeGTy288JhL7i75k2pn3lWgcQ82TP2FRgy0AK27zTs1Vx62i'];

export default function FormRentables({ fields }: {fields: Page_Flexcontent_Flex_Form;}) {
  const widgetRef = useRef(null);
  const location = useLocation();
  const sectionRef = useRef(null);
  const inView = useInView(sectionRef);

  useEffect(() => {
    if (!fields.widgetId) return;

    const observer = new MutationObserver(() => {
      if (WIDGET_LIST_VIEWS.includes((fields.widgetId as string))) {
        const widgetItems = document.getElementsByClassName('widget-123boeken-item');

        for (let i = 0; i < widgetItems.length; i++) {
          widgetItems[i].classList.add('widget-123boeken-item--custom');
        }
      } else {
        const widgetTitles = document.getElementsByClassName('widget-123boeken-title');
        const widgetDescriptions = document.getElementsByClassName('widget-123boeken-description');

        for (let i = 0; i < widgetDescriptions.length; i++) {
          widgetDescriptions[i].appendChild(widgetTitles[i]);
        }
      }
    });

    if (widgetRef.current) {
      observer.observe(widgetRef.current, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [location.pathname, inView, fields.widgetId]);

  useEffect(() => {
    if (!fields.widgetId) return;

    let script = document.createElement('script');
    script.id = `123boeken-script-${location.pathname}`;
    script.src = `https://widget.123boeken.nl/widgets.css?key=${fields.widgetId}&amp;data=%7B%22widget%22%3A%22123boeken%22%2C%22apikey%22%3A%22${fields.widgetId}%22%7D&amp;cb=1691532000000`;
    script.defer = true;

    document.head.appendChild(script);

    const removeScript = () => {
      document.head.removeChild(script);
    };

    return () => removeScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <section id="reserveer" ref={sectionRef} data-component="FormRentables" className="pb-6 lg:pb-20">
      <img src="/images/123boeken-arrow.svg" alt="" />
      <div className="container">
        {fields.description && <Content className="content">{fields.description}</Content>}
        {fields.link &&
        <CustomLosseLink className="btn mt-9" to={fields.link.url || '/'}>
            {fields.link.title}
          </CustomLosseLink>}

        {fields.title && <Content className="content mt-20">{fields.title}</Content>}
        {fields.widgetId &&
        <>
            <script type="text/javascript" src={`https://widget.123boeken.nl/${fields.widgetId}/widgets.js`}></script>
            <div className="relative mt-32">
              <div className="flex justify-center">
                <div className="absolute -top-16 w-3/4 rounded-lg h-24 bg-gradient-to-t from-eh-manhattan-400 to-eh-manhattan-600"></div>
                <div
                key={fields.widgetId}
                ref={widgetRef}
                id="reserveren"
                data-widget="123boeken"
                data-lang={location.pathname.includes('/online-booking-eng/') ? 'en' : location.pathname.includes('/online-buchen/') ? 'de' : 'nl'}
                data-type-active="overview"
                data-apikey={fields.widgetId}
                className="z-10">

                  <div className="-mt-10 text-center">
                    Komt hier niets te staan? <br />
                    Ververs de pagina a.u.b.
                  </div>
                </div>
              </div>
            </div>
          </>}

      </div>
    </section>);

}