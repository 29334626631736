import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerLanding from './BannerLanding'
import BannerDetail from './BannerDetail'
import BannerBlog from './BannerBlog'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    landing: BannerLanding,
    detail: BannerDetail,
    blog: BannerBlog
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
