import { LosseKruumels } from '@ubo/losse-sjedel'

export default function Breadcrumbs() {
  return (
    <LosseKruumels
      type="fragment"
      className="children-ol:flex children-ol:flex-wrap [&_a:first-child]:!ml-0 children-a:mx-3 [&_a.active]:!font-bold children-a:text-sm children-a:font-light children-a:aria-current-page:!font-bold children-li:flex children-ol:items-center text-black/80 hover:children-a:underline children-a:first-letter:uppercase children-a:inline-block"
      seperator={
        <span className="flex items-center">
          <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.34187 3.35165L1.85562 0.865398C1.75413 0.764512 1.61684 0.707886 1.47374 0.707886C1.33064 0.707886 1.19335 0.764512 1.09186 0.865398C1.0411 0.915753 1.0008 0.975662 0.973299 1.04167C0.945799 1.10768 0.931641 1.17848 0.931641 1.24998C0.931641 1.32149 0.945799 1.39229 0.973299 1.45829C1.0008 1.5243 1.0411 1.58421 1.09186 1.63457L3.58353 4.1154C3.6343 4.16575 3.6746 4.22566 3.7021 4.29167C3.7296 4.35768 3.74376 4.42848 3.74376 4.49998C3.74376 4.57149 3.7296 4.64229 3.7021 4.70829C3.6746 4.7743 3.6343 4.83421 3.58353 4.88457L1.09186 7.3654C0.989867 7.46668 0.93228 7.60433 0.931772 7.74807C0.931264 7.89181 0.987877 8.02986 1.08916 8.13186C1.19044 8.23386 1.32809 8.29144 1.47182 8.29195C1.61556 8.29246 1.75362 8.23585 1.85562 8.13457L4.34187 5.64832C4.64617 5.34363 4.8171 4.93061 4.8171 4.49998C4.8171 4.06936 4.64617 3.65634 4.34187 3.35165V3.35165Z"
              fill="black"
              fillOpacity="0.8"
            />
          </svg>
        </span>
      }
    />
  )
}
