import clsx from 'clsx'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import ReactModal, { setAppElement } from 'react-modal'
import useMediaQuery from '~/hooks/useMediaQuery'

interface IModal {
  isOpen: boolean
  requestClose: () => void
  children: ReactNode
  className?: string
  custom?: boolean
}

export default function Modal({ isOpen, requestClose, children, className, custom }: IModal) {
  useEffect(() => {
    const { documentElement: rootEl } = document

    if (rootEl) {
      setAppElement(rootEl)

      if (isOpen) {
        rootEl.classList.add('overflow-hidden')
      } else {
        rootEl.classList.remove('overflow-hidden')
      }
    }
  }, [isOpen])

  const defaultStyles = {
    overlay: {
      zIndex: 999
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'linear-gradient(360deg, #F1BD92 0%, #E9A871 100%)',
      maxHeight: '75%',
      borderRadius: '.75rem',
      padding: '10px'
    }
  }

  return (
    <ReactModal style={defaultStyles} isOpen={isOpen} onRequestClose={requestClose}>
      <div className={clsx(className ?? undefined, 'relative p-2 lg:p-10 pt-10 md:pt-0')}>
        <button className="absolute top-0 right-0 w-8 h-8 hover:animate-pulse" onClick={requestClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="164.64 94.64 370.17 370.16"
          >
            <path
              fill={clsx(custom ? '#fff' : '#000')}
              d="m435.68 94.641h-171.36c-54.879 0-99.68 44.801-99.68 99.68v170.8c0 54.879 44.801 99.68 99.68 99.68h170.8c54.879 0 99.68-44.801 99.68-99.68v-170.8c0.55859-54.879-44.242-99.68-99.121-99.68zm-33.039 220.08c5.0391 5.0391 5.0391 12.879 0 17.922-2.2383 2.2383-5.6016 3.9219-8.9609 3.9219-3.3594 0-6.7188-1.1211-8.9609-3.9219l-34.719-34.719-34.719 34.719c-2.2383 2.2383-5.6016 3.9219-8.9609 3.9219-3.3594 0-6.7188-1.1211-8.9609-3.9219-5.0391-5.0391-5.0391-12.879 0-17.922l34.719-34.719-34.719-34.719c-5.0391-5.0391-5.0391-12.879 0-17.922 5.0391-5.0391 12.879-5.0391 17.922 0l34.719 34.719 34.719-34.719c5.0391-5.0391 12.879-5.0391 17.922 0 5.0391 5.0391 5.0391 12.879 0 17.922l-34.719 34.719z"
            />
          </svg>
        </button>
        {children}
      </div>
    </ReactModal>
  )
}
