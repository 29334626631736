/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import CustomLosseLink from '~/components/elements/CustomLosseLink'

export default function HeaderMenuMobile() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <>
      {header?.menuHeader?.map((l, index) => {
        const last = index === Number(header.menuHeader?.length) - 1

        return (
          <>
            <motion.li
              onClick={() => {
                if (currentIndex !== index) {
                  setCurrentIndex(index)
                }
              }}
              className={clsx(last && 'mb-12', 'group')}
              key={index}
            >
              <div className="flex flex-wrap items-center">
                <button className="aria-current-page:text-eh-flame-pea inline-flex items-center group-hover:text-eh-flame-pea smooth font-bold py-1">
                  <span className="text-xl">{l?.link?.title}</span>
                  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-3">
                    <path
                      d="M1.1574 0.967638C1.0871 1.03736 1.0313 1.12031 0.993227 1.2117C0.95515 1.3031 0.935547 1.40113 0.935547 1.50014C0.935547 1.59915 0.95515 1.69718 0.993227 1.78857C1.0313 1.87996 1.0871 1.96291 1.1574 2.03264L4.59239 5.46764C4.66268 5.53736 4.71848 5.62031 4.75656 5.71171C4.79463 5.8031 4.81424 5.90113 4.81424 6.00014C4.81424 6.09915 4.79463 6.19718 4.75656 6.28857C4.71848 6.37996 4.66268 6.46292 4.59239 6.53264L1.1574 9.96764C1.0871 10.0374 1.0313 10.1203 0.993227 10.2117C0.95515 10.3031 0.935547 10.4011 0.935547 10.5001C0.935547 10.5991 0.95515 10.6972 0.993227 10.7886C1.0313 10.88 1.0871 10.9629 1.1574 11.0326C1.29792 11.1723 1.48801 11.2507 1.68614 11.2507C1.88428 11.2507 2.07437 11.1723 2.21489 11.0326L5.65739 7.59014C6.07874 7.16826 6.31541 6.59639 6.31541 6.00014C6.31541 5.40389 6.07874 4.83201 5.65739 4.41014L2.21489 0.967638C2.07437 0.82795 1.88428 0.749542 1.68614 0.749542C1.48801 0.749542 1.29792 0.82795 1.1574 0.967638V0.967638Z"
                      fill="black"
                      fillOpacity="0.8"
                    />
                  </svg>
                </button>
              </div>
              <AnimatePresence mode="wait">
                {l?.submenu && currentIndex === index && (
                  <motion.div
                    initial={{ x: '-100vw' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100vw' }}
                    transition={{ bounce: 0 }}
                    className="fixed left-0 top-0 z-50 inset-0 flex flex-col items-center justify-center bg-gradient-to-t from-eh-manhattan-400 to-eh-manhattan-600"
                  >
                    <div className="h-full flex flex-col items-center justify-between overflow-auto pt-20 pb-20">
                      <ul className="pl-0 w-9/12">
                        <li className="mb-4 flex items-center gap-2">
                          <button
                            type="button"
                            onClick={() => {
                              setCurrentIndex(-1)
                            }}
                            className="-ml-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                          </button>
                          <CustomLosseLink className="text-xl sm:text-2xl font-bold" to={l.link?.url || '/'}>
                            {l.link?.title}
                          </CustomLosseLink>
                        </li>
                        {l.submenu.map((edge) => {
                          return (
                            <li key={edge?.link?.title}>
                              <CustomLosseLink className="block text-base sm:text-lg font-light py-1" to={edge?.link?.url || '/'}>
                                {edge?.link?.title}
                              </CustomLosseLink>
                            </li>
                          )
                        })}
                      </ul>
                      <div className="w-11/12 mt-8">
                        <LosseLink to="" className="relative group block rounded-xl overflow-hidden">
                          <LossePlaatjie
                            maxWidth={2000}
                            src={header.menuHeader![currentIndex]?.cta?.image}
                            className="object-cover w-full h-[180px]"
                          />

                          <div className="absolute bottom-0 left-0 right-0 bg-white">
                            <div className="relative w-full h-full">
                              <svg
                                className="absolute w-full h-auto left-0 -top-4"
                                width="490"
                                height="97"
                                viewBox="0 0 490 97"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 10.1838L20.4167 8.39506C40.8333 6.47131 81.6667 3.09631 122.5 1.17256C163.333 -0.616191 204.167 -0.61619 245 2.99506C285.833 6.47131 326.667 13.8963 367.5 15.5838C408.333 17.2713 449.167 13.8963 469.583 11.9726L490 10.1838V96.5838H469.583C449.167 96.5838 408.333 96.5838 367.5 96.5838C326.667 96.5838 285.833 96.5838 245 96.5838C204.167 96.5838 163.333 96.5838 122.5 96.5838C81.6667 96.5838 40.8333 96.5838 20.4167 96.5838H0V10.1838Z"
                                  fill="white"
                                />
                              </svg>
                              <div className="relative pb-6 px-2 sm:px-6">
                                <Button
                                  arrow
                                  className="transparent group-hover:[&>svg]:translate-x-1 group-hover:underline max-w-full inline-flex flex-wrap"
                                  to={header.menuHeader![currentIndex]?.cta?.link?.url}
                                >
                                  <span className="text-base md:text-lg 2xl:text-xl">
                                    {header.menuHeader![currentIndex]?.cta?.link?.title}
                                  </span>
                                </Button>
                                <Content>{header.menuHeader![currentIndex]?.cta?.link?.title}</Content>
                              </div>
                            </div>
                          </div>
                        </LosseLink>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.li>
          </>
        )
      })}
    </>
  )
}
