function BannerWave({ className }: { className?: string }) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 2241 481" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M0 82.6L93.3333 74.3519C186.667 65.4813 373.333 49.9188 560 41.0482C746.667 32.8 933.333 32.8 1120 49.4519C1306.67 65.4813 1493.33 99.7188 1680 107.5C1866.67 115.281 2053.33 99.7188 2146.67 90.8482L2240 82.6V481H2146.67C2053.33 481 1866.67 481 1680 481C1493.33 481 1306.67 481 1120 481C933.333 481 746.667 481 560 481C373.333 481 186.667 481 93.3333 481H0V82.6Z"
        fill="#FFF"
      />
      <path
        d="M0 186.896L99.4167 161.399C198.833 133.977 397.667 85.8679 596.5 58.4459C795.333 32.9482 994.167 32.9482 1193 84.4246C1391.83 133.977 1590.67 239.816 1789.5 263.87C1988.33 287.925 2141.08 242.922 2240.5 215.5L2239 294C2156.5 328.5 1988.33 407.5 1789.5 407.5C1590.67 407.5 1391.83 407.5 1193 407.5C994.167 407.5 795.333 407.5 596.5 407.5C397.667 407.5 198.833 407.5 99.4167 407.5H0V186.896Z"
        fill="#E9A871"
      />
      <g opacity="0.22">
        <path
          d="M0 236.746L93.3333 217.141C186.667 196.056 373.333 159.064 560 137.979C746.667 118.373 933.333 118.373 1120 157.954C1306.67 196.056 1493.33 277.437 1680 295.933C1866.67 314.429 2053.33 277.437 2146.67 256.352L2240 236.746V357.5H2146.67C2053.33 357.5 1866.67 357.5 1680 357.5C1493.33 357.5 1306.67 357.5 1120 357.5C933.333 357.5 746.667 357.5 560 357.5C373.333 357.5 186.667 357.5 93.3333 357.5H0L0 236.746Z"
          fill="#FFF"
        />
      </g>
      <g>
        <path
          d="M0 271.746L93.3333 252.141C186.667 231.056 373.333 194.064 560 172.979C746.667 153.373 933.333 153.373 1120 192.954C1306.67 231.056 1493.33 312.437 1680 330.933C1866.67 349.429 2053.33 312.437 2146.67 291.352L2240 271.746V1218.73H2146.67C2053.33 1218.73 1866.67 1218.73 1680 1218.73C1493.33 1218.73 1306.67 1218.73 1120 1218.73C933.333 1218.73 746.667 1218.73 560 1218.73C373.333 1218.73 186.667 1218.73 93.3333 1218.73H0V271.746Z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default BannerWave
