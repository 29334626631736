import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import EmployeeCTA from '~/components/elements/EmployeeCTA'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentDefault" className="section">
      <div className="container">
        <div className="max-w-screen-lg mx-auto">
          {fields.description && (
            <article>
              <Content className="content [&_img]:lg:h-[520px] [&_img]:object-cover">{fields?.description}</Content>
            </article>
          )}

          {fields.links && (
            <div className="flex items-center gap-2 mt-4">
              {fields.links.map((link, index) => (
                <Button arrow className={clsx(index >= 1 && 'secondary', 'btn')} key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          )}

          {fields.employee && (
            <div className="mt-8 xs:mt-14 lg:mt-32">
              <EmployeeCTA fields={fields.employee} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
