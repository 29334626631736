import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import ContentShell from './Content/ContentShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import MiscShell from './Misc/MiscShell'
import CtaShell from './Cta/CtaShell'
import ExperiencesShell from './Experiences/ExperiencesShell'
import ActivitiesShell from './Activities/ActivitiesShell'
import VacanciesShell from './Vacancies/VacanciesShell'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Content`]: ContentShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Experiences`]: ExperiencesShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_Misc`]: MiscShell,
    [`${prefix}_Flex_Activities`]: ActivitiesShell,
    [`${prefix}_Flex_Vacancies`]: VacanciesShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  return <LosseFlex flex={flex as any} flexers={flexers as any} />
}
