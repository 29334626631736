import type { ComponentPropsWithoutRef } from 'react'
import type { Maybe } from '~/graphql/types'
import CustomLosseLink from './CustomLosseLink'

export interface IButton extends ComponentPropsWithoutRef<'button'> {
  onClick?: () => void
  as?: 'a' | 'button'
  arrow?: boolean
}

export interface IAnchor extends ComponentPropsWithoutRef<'a'> {
  onClick?: () => void
  to?: Maybe<string | undefined>
  as?: 'a' | 'button'
  arrow?: boolean
}

type ButtonProps = IButton | IAnchor

export default function Button({ arrow = false, children, className = '', as = 'a', ...props }: ButtonProps) {
  return (
    <>
      {as === 'a' && (
        // @ts-ignore
        <CustomLosseLink className={`${className} btn`} {...props}>
          {children}
          {arrow && (
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.25 7.50001C16.2445 6.90814 16.006 6.34227 15.5862 5.92501L10.76 1.08752C10.5492 0.877986 10.2641 0.760376 9.96687 0.760376C9.66967 0.760376 9.38453 0.877986 9.17375 1.08752C9.06831 1.1921 8.98461 1.31653 8.9275 1.45362C8.87038 1.59071 8.84098 1.73775 8.84098 1.88627C8.84098 2.03478 8.87038 2.18182 8.9275 2.31891C8.98461 2.45601 9.06831 2.58043 9.17375 2.68502L12.875 6.37501H1.625C1.32663 6.37501 1.04048 6.49354 0.829505 6.70452C0.618527 6.91549 0.5 7.20164 0.5 7.50001C0.5 7.79838 0.618527 8.08453 0.829505 8.2955C1.04048 8.50648 1.32663 8.62501 1.625 8.62501H12.875L9.17375 12.3263C8.96191 12.5366 8.8423 12.8225 8.84125 13.121C8.84019 13.4196 8.95778 13.7063 9.16812 13.9181C9.37847 14.13 9.66436 14.2496 9.9629 14.2506C10.2614 14.2517 10.5482 14.1341 10.76 13.9238L15.5862 9.08626C16.0088 8.66624 16.2475 8.09578 16.25 7.50001V7.50001Z"
                fill="white"
              />
            </svg>
          )}
        </CustomLosseLink>
      )}
      {as === 'button' && (
        // @ts-ignore
        <button type="button" className={`${className} btn`} {...props}>
          {children}
          {arrow && (
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.25 7.50001C16.2445 6.90814 16.006 6.34227 15.5862 5.92501L10.76 1.08752C10.5492 0.877986 10.2641 0.760376 9.96687 0.760376C9.66967 0.760376 9.38453 0.877986 9.17375 1.08752C9.06831 1.1921 8.98461 1.31653 8.9275 1.45362C8.87038 1.59071 8.84098 1.73775 8.84098 1.88627C8.84098 2.03478 8.87038 2.18182 8.9275 2.31891C8.98461 2.45601 9.06831 2.58043 9.17375 2.68502L12.875 6.37501H1.625C1.32663 6.37501 1.04048 6.49354 0.829505 6.70452C0.618527 6.91549 0.5 7.20164 0.5 7.50001C0.5 7.79838 0.618527 8.08453 0.829505 8.2955C1.04048 8.50648 1.32663 8.62501 1.625 8.62501H12.875L9.17375 12.3263C8.96191 12.5366 8.8423 12.8225 8.84125 13.121C8.84019 13.4196 8.95778 13.7063 9.16812 13.9181C9.37847 14.13 9.66436 14.2496 9.9629 14.2506C10.2614 14.2517 10.5482 14.1341 10.76 13.9238L15.5862 9.08626C16.0088 8.66624 16.2475 8.09578 16.25 7.50001V7.50001Z"
                fill="white"
              />
            </svg>
          )}
        </button>
      )}
    </>
  )
}
