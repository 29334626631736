import { LosseBlogBink, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import Content from '~/components/elements/Content'
import NothingFound from '~/components/elements/NothingFound'
import Pagination from '~/components/elements/Pagination'
import Activity from './post-types/Activity'
import Post from '../Posts/post-types/Post'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Activities, WpPageInfo } from '~/graphql/types'
import clsx from 'clsx'

export default function ActivitiesOverview({ fields }: { fields: Page_Flexcontent_Flex_Activities }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="ActivitiesOverview">
      <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Activities) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Activity: Activity,
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Activities }) {
  const { hasNextPage, hasPrevPage } = useLosseBlogBink()
  const postTypeName = getPostTypeName(fields)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams.has('_f')) {
      setSearchParams(
        (params) => {
          if (!fields.filters?.[0]?.databaseId) return params
          params.set('_f', fields.filters?.[0]?.databaseId.toString())
          return params
        },
        {
          state: {
            scroll: false
          }
        }
      )
    }
  }, [searchParams, setSearchParams, fields.filters])

  return (
    <section data-component="ActivitiesOverview" className="section flex flex-col gap-8 lg:gap-20">
      <div className="relative">
        <div className="container relative z-20">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-10">
            {fields?.description && (
              <div className="lg:col-span-7">
                <Content className="content">{fields?.description}</Content>
              </div>
            )}
            {fields?.subdescription && (
              <div className="lg:col-span-5">
                <Content className="content">{fields?.subdescription}</Content>
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="PostsBlog" className="container">
        <div className="min-h-[100px] relative pt-3 lg:pt-10 px-3 pb-[70px] lg:pb-20 flex justify-center w-[90%] mx-auto -mb-14 md:-mb-6 bg-gradient-to-b lg:rounded-[10px] rounded-[5px] from-[#E9A871] to-[#F1BD92]">
          {fields.filters && fields.filters.length > 1 && (
            <div className="relative w-fit bg-white rounded-full">
              {/* <div className="absolute bg-gradient-to-l rounded-full from-white z-20 to-transparent w-[40px] h-full right-0 top-0 lg:hidden block" /> */}

              <div className="bg-white relative overflow-x-scroll z-10 no-scrollbar w-full flex flex-col lg:flex-row gap-3 lg:gap-8 rounded-lg lg:rounded-full py-[6px] px-2">
                {fields.filters.map((f, index) => (
                  <div
                    onClick={() => {
                      setSearchParams(
                        (params) => {
                          if (!f?.databaseId) return params
                          params.delete('_request')
                          params.set('_f', f?.databaseId.toString())
                          return params
                        },
                        {
                          state: {
                            scroll: false
                          }
                        }
                      )
                    }}
                    className={clsx(
                      searchParams.get('_f') === f?.databaseId.toString() && 'bg-eh-cherokee',
                      'max-lg:text-center rounded-full font-bold whitespace-nowrap px-6 py-1 cursor-pointer hover:bg-eh-cherokee-400 smooth'
                    )}
                    key={index}
                  >
                    {f?.name}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {fields.posts?.edges.length !== 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-8">
              {fields.posts?.edges?.map((edge) => {
                if (!edge || !edge.node) return null

                const Component = PostTypes[postTypeName] || PostTypes.Post

                return (
                  <div key={edge.node.uri} className="col-span-1">
                    <Component data={edge.node} />
                  </div>
                )
              })}
            </div>
            {(hasNextPage || hasPrevPage) && (
              <div className="flex justify-center py-6 lg:py-10">
                <Pagination />
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center">
            <NothingFound />
          </div>
        )}
      </div>
    </section>
  )
}
