import Content from '~/components/elements/Content';
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types';
import Breadcrumbs from '~/components/elements/Breadcrumbs';
import { LossePlaatjie, useLocation } from '@ubo/losse-sjedel';
import Button from '~/components/elements/Button';
import clsx from 'clsx';
import { useEffect } from 'react';
import BannerWave from '~/components/elements/BannerWave';

export default function BannerDetail({ fields }: {fields: Page_Flexcontent_Flex_Banner;}) {
  const location = useLocation();

  useEffect(() => {
    // add a script tag with code to the body
    const script = document.createElement('script');
    script.innerText = `
    (function(g,s,t,p,l,n){
      g["_gstpln"]={};
      (l=s.createElement(t)),(n=s.getElementsByTagName(t)[0]);
      l.async=1;l.src=p;n.parentNode.insertBefore(l,n);
    })(window,document,"script","https://cdn.guestplan.com/widget.js");
      _gstpln.accessKey = "e5f1b279244b9c42f58587334f95cb0b913efacc";
      _gstpln.open = false;
      _gstpln.showFab = false;`;



    if (fields.showReservationWidget) {
      document.body.append(script);
    }

    setTimeout(() => {
      if (location.hash === '#gstpln_openWidget') {

        // change the _gstpln.open in the const script to true
        script.innerText = `
        (function(g,s,t,p,l,n){
          g["_gstpln"]={};
          (l=s.createElement(t)),(n=s.getElementsByTagName(t)[0]);
          l.async=1;l.src=p;n.parentNode.insertBefore(l,n);
        })(window,document,"script","https://cdn.guestplan.com/widget.js");
          _gstpln.accessKey = "e5f1b279244b9c42f58587334f95cb0b913efacc";`;
      }
    }, 500);

    const element = (((document.getElementsByClassName('ft-widget-b2') || []) as unknown) as HTMLDivElement[]);

    if (fields.showReservationWidget && element) {
      Array.from(element)?.forEach((_element) => {
        _element.style.cssText = 'display: block !important';
      });
    }

    return () => {
      Array.from(element)?.forEach((_element) => {
        _element.style.cssText = 'display: none !important';
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.showReservationWidget, location.pathname, location.hash]);

  return (
    <section key={location.pathname} data-component="BannerDetail" className="relative max-w-[2000px] mx-auto">
      <div className="absolute h-[60%] w-full">
        <LossePlaatjie
          maxWidth={2000}
          src={fields.image}
          alt="detail-image"
          className={clsx(
            fields.imagePosition === 'bottom' && 'object-bottom',
            fields.imagePosition === 'top' && 'object-top',
            fields.imagePosition === 'center' && 'object-center',
            fields.imagePosition === 'right' && 'object-right',
            fields.imagePosition === 'left' && 'object-left',
            'object-cover absolute bg-[#EFF2FB] w-full h-full bottom-0 left-0 -z-20'
          )} />


        {fields.hasorangewave ?
        <BannerWave className="absolute w-[calc(100%_+_2px)] -bottom-[360px]" /> :

        <svg
          className="absolute pointer-events-none w-full h-auto bottom-0 left-0 -z-10"
          width="2240"
          height="86"
          viewBox="0 0 2240 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">

            <path
            d="M0 46.7376L93.3333 38.5283C186.667 29.6994 373.333 14.2102 560 5.38134C746.667 -2.82795 933.333 -2.82794 1120 13.7455C1306.67 29.6994 1493.33 63.7757 1680 71.5203C1866.67 79.2649 2053.33 63.7757 2146.67 54.9469L2240 46.7376V86H2146.67C2053.33 86 1866.67 86 1680 86C1493.33 86 1306.67 86 1120 86C933.333 86 746.667 86 560 86C373.333 86 186.667 86 93.3333 86H0L0 46.7376Z"
            fill="white" />

          </svg>}

      </div>

      <div className="container lg:!max-w-[1440px] relative z-20 section">
        <div className="shadow-eh-primary max-w-[850px] bg-white mt-[100px] xs:mt-[170px] lg:mt-[370px] lg:-mb-[50px] lg:rounded-[10px] rounded-[5px] pt-4 xs:pt-6 md:pt-10 p-5 xs:p-10 lg:pt-10 lg:p-20">
          <div>
            <Breadcrumbs />
          </div>
          {fields?.description &&
          <div className="pt-3 sm:pt-5">
              <Content className="content md:children-h1:pb-5">{fields?.description}</Content>
            </div>}

          {fields.links &&
          <div className="flex md:flex-row flex-col items-start md:items-center gap-4 mt-4 md:mt-10">
              {fields.links.map((link, index) =>
            <Button
              rel="noreferrer"
              target={link?.link?.target || ''}
              arrow
              className={clsx(index >= 1 && 'primary-outline', 'btn')}
              key={index}
              to={link?.link?.url || '/'}>

                  {link?.link?.title}
                </Button>
            )}
            </div>}

        </div>
      </div>
    </section>);

}