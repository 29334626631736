import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentVideo({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentVideo" className="section">
      <div className="container">
        <div className="grid grid-cols-12 items-center lg:gap-x-12">
          <div className="col-span-12 mb-6 lg:mb-0 lg:col-span-7">
            {fields?.videoUrl && (
              <video controls autoPlay muted className="max-w-full aspect-video rounded-xl overflow-hidden">
                <source src={fields.videoUrl} type="video/mp4" />
              </video>
            )}
          </div>
          <div className="col-span-12 lg:col-span-5">
            <Content className="content">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
