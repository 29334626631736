import { type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import CustomLosseLink from '~/components/elements/CustomLosseLink'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  return (
    <nav className="relative left-0 top-0 right-0 z-50 h-[70px] lg:h-[110px] 2xl:h-[140px] bg-white transition-all">
      <div className="absolute z-10 h-full w-full top-0 left-0 bg-gradient-to-b from-transparent to-[#EFF2FB]" />
      {/* <div className="container h-full"> */}
      <div className="max-w-screen-2xl container z-20 relative mx-auto flex h-full items-center justify-between">
        <CustomLosseLink to="/" aria-label="Home" className="relative z-[999]">
          <img src="/images/logo.png" className="lg:w-[unset] w-[80%]" alt="Watersportcenter Eemhof" />
        </CustomLosseLink>

        <HeaderDesktop />
        <HeaderMobile />
      </div>
      {/* </div> */}
    </nav>
  )
}
