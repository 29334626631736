import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner, Post } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'

export default function BannerBlog({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const data = useLoaderData<{ request: { url: string }; page: Post }>()

  return (
    <section data-component="BannerBlog" className="relative section md:mb-0 mb-3">
      <div className="absolute bg-[#EFF2FB] w-full h-full xl:h-[93%] top-0 left-0 -z-20">
        <svg
          className="absolute pointer-events-none w-full h-auto bottom-0 left-0 -z-10"
          width="2240"
          height="86"
          viewBox="0 0 2240 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 46.7376L93.3333 38.5283C186.667 29.6994 373.333 14.2102 560 5.38134C746.667 -2.82795 933.333 -2.82794 1120 13.7455C1306.67 29.6994 1493.33 63.7757 1680 71.5203C1866.67 79.2649 2053.33 63.7757 2146.67 54.9469L2240 46.7376V86H2146.67C2053.33 86 1866.67 86 1680 86C1493.33 86 1306.67 86 1120 86C933.333 86 746.667 86 560 86C373.333 86 186.667 86 93.3333 86H0L0 46.7376Z"
            fill="white"
          />
        </svg>
      </div>

      <div className="container flex flex-col gap-6 md:gap-11 md:pb-0 pb-3 relative z-20">
        <div>
          <Breadcrumbs />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 xl:gap-28">
          <div className="col-span-1">
            <LossePlaatjie
              autoheight={false}
              maxWidth={2000}
              className="lg:rounded-[10px] rounded-[5px] lg:h-[388px] object-cover"
              src={fields.image}
              alt="banner-blog-image"
            />
          </div>
          <div className="col-span-1 flex justify-center flex-col gap-4 md:gap-7">
            {data.page.recap?.readtime && (
              <div className="flex items-center gap-3 text-black/80">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_238_1802)">
                    <path
                      d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0V0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63856 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59985 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5V16.5Z"
                      fill="#E9A871"
                    />
                    <path
                      d="M8.99984 4.5C8.80093 4.5 8.61016 4.57902 8.46951 4.71967C8.32886 4.86032 8.24984 5.05109 8.24984 5.25V8.49374L5.72159 10.0777C5.55251 10.1834 5.43232 10.3518 5.38745 10.5461C5.34258 10.7403 5.37672 10.9444 5.48234 11.1135C5.58796 11.2826 5.75642 11.4028 5.95066 11.4476C6.1449 11.4925 6.34901 11.4584 6.51809 11.3527L9.39809 9.55274C9.5069 9.48457 9.59639 9.38959 9.65798 9.27692C9.71957 9.16424 9.7512 9.03764 9.74984 8.90924V5.25C9.74984 5.05109 9.67082 4.86032 9.53017 4.71967C9.38952 4.57902 9.19875 4.5 8.99984 4.5Z"
                      fill="#E9A871"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_238_1802">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {data.page.recap?.readtime}
              </div>
            )}
            {fields?.description && <Content className="content-sm text-black/80">{fields?.description}</Content>}
            <div className="flex items-center gap-7">
              <Content className="content">{fields.sharedescription}</Content>
              <div className="flex flex-row items-center gap-2">
                <LinkedinShareButton className="group" url={data.request.url}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_238_1808)">
                      <path
                        d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.7805 0.83733 6.79048C0.00476611 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0ZM7.80132 16.7895H5.40158V9.05908H7.80132V16.7895ZM6.60001 8.00395C6.32383 8.00509 6.05351 7.92433 5.82322 7.77187C5.59294 7.61941 5.41302 7.40211 5.3062 7.14742C5.19939 6.89273 5.17049 6.61209 5.22314 6.34097C5.27579 6.06986 5.40764 5.82044 5.60202 5.62424C5.7964 5.42804 6.04458 5.29388 6.31519 5.2387C6.5858 5.18352 6.8667 5.20981 7.12237 5.31424C7.37805 5.41867 7.59702 5.59656 7.75162 5.82541C7.90621 6.05427 7.98949 6.32382 7.99093 6.6C7.9917 6.97034 7.84582 7.32591 7.58518 7.589C7.32453 7.85208 6.97033 8.00127 6.60001 8.00395ZM16.7895 16.7895H14.3912V13.0263C14.3912 12.1289 14.3738 10.9783 13.1436 10.9783C11.9133 10.9783 11.6962 11.9538 11.6962 12.9612V16.7895H9.30658V9.05908H11.6093V10.1128H11.6426C11.9625 9.50487 12.7455 8.86369 13.9136 8.86369C16.3422 8.86369 16.7895 10.4645 16.7895 12.5429V16.7895Z"
                        fill="#E9A871"
                        className="fill-eh-porsche group-hover:fill-eh-burnt-sienna smooth"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_238_1808">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </LinkedinShareButton>
                <FacebookShareButton className="group" url={data.request.url}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 0C4.93462 0 0 4.93462 0 11C0 17.0649 4.93462 22 11 22C17.0649 22 22 17.0649 22 11C22 4.93462 17.0658 0 11 0ZM13.7356 11.3873H11.946V17.7659H9.29413C9.29413 17.7659 9.29413 14.2806 9.29413 11.3873H8.03355V9.13284H9.29413V7.67465C9.29413 6.63031 9.79038 4.99843 11.9704 4.99843L13.9354 5.00596V7.19435C13.9354 7.19435 12.7413 7.19435 12.5091 7.19435C12.277 7.19435 11.9469 7.31044 11.9469 7.80847V9.13329H13.9673L13.7356 11.3873Z"
                      className="fill-eh-porsche group-hover:fill-eh-burnt-sienna smooth"
                    />
                  </svg>
                </FacebookShareButton>
                <TwitterShareButton className="group" url={data.request.url}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_238_1820)">
                      <path
                        d="M11 0C4.9258 0 0 4.9258 0 11C0 17.0742 4.9258 22 11 22C17.0742 22 22 17.0742 22 11C22 4.9258 17.0742 0 11 0ZM16.0225 8.57663C16.0273 8.68489 16.0297 8.79366 16.0297 8.90292C16.0297 12.2392 13.4902 16.0864 8.84602 16.0866H8.84619H8.84602C7.42017 16.0866 6.09334 15.6687 4.97598 14.9525C5.17354 14.9758 5.37462 14.9874 5.57822 14.9874C6.7612 14.9874 7.84985 14.5839 8.7141 13.9066C7.60883 13.8861 6.67694 13.1562 6.35535 12.1529C6.50926 12.1825 6.66754 12.1986 6.82985 12.1986C7.0603 12.1986 7.28354 12.1675 7.4957 12.1096C6.34041 11.8783 5.47012 10.8573 5.47012 9.63474C5.47012 9.62332 5.47012 9.61308 5.47046 9.60251C5.81068 9.79167 6.19975 9.90547 6.614 9.91823C5.93607 9.46588 5.49043 8.69261 5.49043 7.81662C5.49043 7.35403 5.61548 6.92065 5.83234 6.54753C7.07742 8.07527 8.93817 9.08 11.0366 9.18558C10.9933 9.00061 10.971 8.80792 10.971 8.60986C10.971 7.21606 12.1017 6.08528 13.496 6.08528C14.2223 6.08528 14.8783 6.39227 15.339 6.88306C15.9142 6.76959 16.4543 6.55945 16.9423 6.27025C16.7534 6.85956 16.3533 7.35403 15.832 7.66673C16.3427 7.60564 16.8295 7.47018 17.2818 7.2691C16.944 7.7755 16.5154 8.22029 16.0225 8.57663Z"
                        className="fill-eh-porsche group-hover:fill-eh-burnt-sienna smooth"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_238_1820">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </TwitterShareButton>
                <EmailShareButton className="group" url={data.request.url}>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="11" className="fill-eh-porsche group-hover:fill-eh-burnt-sienna smooth" />
                    <g clipPath="url(#clip0_238_1822)">
                      <path
                        d="M14.5 5.49988H7.5C6.8372 5.50067 6.20178 5.76432 5.73311 6.23299C5.26444 6.70166 5.00079 7.33708 5 7.99988V13.9999C5.00079 14.6627 5.26444 15.2981 5.73311 15.7668C6.20178 16.2354 6.8372 16.4991 7.5 16.4999H14.5C15.1628 16.4991 15.7982 16.2354 16.2669 15.7668C16.7356 15.2981 16.9992 14.6627 17 13.9999V7.99988C16.9992 7.33708 16.7356 6.70166 16.2669 6.23299C15.7982 5.76432 15.1628 5.50067 14.5 5.49988ZM7.5 6.49988H14.5C14.7994 6.50047 15.0918 6.59064 15.3395 6.75878C15.5872 6.92692 15.7789 7.16535 15.89 7.44338L12.061 11.2729C11.7792 11.5535 11.3977 11.7111 11 11.7111C10.6023 11.7111 10.2208 11.5535 9.939 11.2729L6.11 7.44338C6.22107 7.16535 6.41281 6.92692 6.66052 6.75878C6.90824 6.59064 7.20061 6.50047 7.5 6.49988ZM14.5 15.4999H7.5C7.10218 15.4999 6.72064 15.3418 6.43934 15.0605C6.15804 14.7792 6 14.3977 6 13.9999V8.74988L9.232 11.9799C9.70131 12.448 10.3371 12.7109 11 12.7109C11.6629 12.7109 12.2987 12.448 12.768 11.9799L16 8.74988V13.9999C16 14.3977 15.842 14.7792 15.5607 15.0605C15.2794 15.3418 14.8978 15.4999 14.5 15.4999Z"
                        fill="#E6E6E6"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_238_1822">
                        <rect width="12" height="12" fill="white" transform="translate(5 5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
