import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormContact from './FormContact'
import FormRequest from './FormRequest'
import FormRentables from './FormRentables'
import FormApply from './FormApply'
import FormLesson from './FormLesson'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: FormContact,
    request: FormRequest,
    rentables: FormRentables,
    apply: FormApply,
    lesson: FormLesson
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
