import { LosseBlogBink, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Content from '~/components/elements/Content'
import Pagination from '~/components/elements/Pagination'
import { useEffect } from 'react'
import NothingFound from '~/components/elements/NothingFound'

export default function PostsBlog({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsBlog">
      <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
        <Overview fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { hasNextPage, hasPrevPage } = useLosseBlogBink()
  const postTypeName = getPostTypeName(fields)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('_c')) {
      const element = document.getElementById('PostsBlog')

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [searchParams])

  return (
    <>
      <section data-component="BannerDefault" className="relative">
        <div className="absolute bg-[#EFF2FB] w-full h-full xl:h-[100%] top-0 left-0 -z-20">
          <svg
            className="absolute pointer-events-none w-full h-auto bottom-0 left-0 -z-10"
            width="2240"
            height="86"
            viewBox="0 0 2240 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 46.7376L93.3333 38.5283C186.667 29.6994 373.333 14.2102 560 5.38134C746.667 -2.82795 933.333 -2.82794 1120 13.7455C1306.67 29.6994 1493.33 63.7757 1680 71.5203C1866.67 79.2649 2053.33 63.7757 2146.67 54.9469L2240 46.7376V86H2146.67C2053.33 86 1866.67 86 1680 86C1493.33 86 1306.67 86 1120 86C933.333 86 746.667 86 560 86C373.333 86 186.667 86 93.3333 86H0L0 46.7376Z"
              fill="white"
            />
          </svg>
        </div>

        <div className="container relative z-20 section">
          <div>
            <Breadcrumbs />
          </div>
          {fields?.description && (
            <div className="pt-3 sm:pt-5">
              <Content className="content">{fields?.description}</Content>
            </div>
          )}
          {fields.filters && (
            <div className="flex justify-start">
              {/* <div className="bg-gradient-to-b flex flex-col gap-3 md:gap-6 mt-5 md:mt-11 lg:rounded-[10px] p-4 md:py-5 md:px-8 rounded-[5px] from-[#E9A871] to-[#F1BD92]">
                <div className="flex gap-4">
                  <div className="text-xl font-bold text-black/80">Filter</div>
                  <div
                    onClick={() => {
                      searchParams.delete('_f')
                      setSearchParams(searchParams)
                    }}
                    className="font-light cursor-pointer hover:underline text-xl text-black/80"
                  >
                    Reset filter
                  </div>
                </div>
                <div className="relative group">
                  <svg
                    className="absolute right-4 top-0 bottom-0 my-auto"
                    width="16"
                    height="9"
                    viewBox="0 0 16 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.0611 0.853986C14.9218 0.714597 14.7564 0.604021 14.5744 0.528579C14.3923 0.453137 14.1972 0.414307 14.0001 0.414307C13.8031 0.414307 13.6079 0.453137 13.4259 0.528579C13.2438 0.604021 13.0784 0.714597 12.9391 0.853986L8.35313 5.43899C8.25936 5.53272 8.13221 5.58538 7.99963 5.58538C7.86704 5.58538 7.73989 5.53272 7.64613 5.43899L3.06113 0.853986C2.77986 0.572592 2.39834 0.414453 2.00048 0.414359C1.60262 0.414265 1.22102 0.572224 0.939627 0.853486C0.658232 1.13475 0.500094 1.51627 0.5 1.91413C0.499906 2.31199 0.657865 2.69359 0.939127 2.97499L5.52513 7.56099C5.85014 7.88603 6.23599 8.14387 6.66065 8.31978C7.08531 8.4957 7.54047 8.58624 8.00013 8.58624C8.45978 8.58624 8.91494 8.4957 9.3396 8.31978C9.76426 8.14387 10.1501 7.88603 10.4751 7.56099L15.0611 2.97499C15.3423 2.6937 15.5003 2.31223 15.5003 1.91449C15.5003 1.51674 15.3423 1.13528 15.0611 0.853986Z"
                      className="fill-black/50 group-hover:fill-black smooth"
                    />
                  </svg>

                  <select
                    className="h-12 w-[300px] rounded-full px-4 appearance-none font-light"
                    onChange={(e) => {
                      searchParams.set('_f', e.target.value)
                      setSearchParams(searchParams)
                    }}
                  >
                    <option selected disabled>
                      Type
                    </option>
                    {fields.filters.map((filter, index) => {
                      return (
                        <option
                          key={index}
                          selected={searchParams.get('_f') === (filter?.databaseId || 0).toString()}
                          className="bg-white"
                          value={filter?.databaseId}
                        >
                          {filter?.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </section>
      <div id="PostsBlog" className="container section">
        <div className="h-[100px] w-[90%] mx-auto -mb-14 md:-mb-6 bg-gradient-to-b lg:rounded-[10px] rounded-[5px] from-[#E9A871] to-[#F1BD92]" />
        {fields.posts?.edges.length !== 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-8">
              {fields.posts?.edges?.map((edge) => {
                if (!edge || !edge.node) return null

                const Component = PostTypes[postTypeName] || PostTypes.Post

                return (
                  <div key={edge.node.uri} className="col-span-1">
                    <Component data={edge.node} />
                  </div>
                )
              })}
            </div>
            {(hasNextPage || hasPrevPage) && (
              <div className="flex justify-center py-6 lg:py-10">
                <Pagination />
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center">
            <NothingFound />
          </div>
        )}
      </div>
    </>
  )
}
