import clsx from 'clsx'

export default function Sun({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        className && className,
        '[background:radial-gradient(50%_50%_at_50%_50%,#E9A871_0%,#FCD19C_100%)] pointer-events-none blur-[100px] sm:blur-[150px] w-[250px] h-[250px] sm:w-[450px] sm:h-[450px]'
      )}
    />
  )
}
